import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const getMapAsync = createAsyncThunk(
    'map/getMapAsync',
    async (dispatch, rejectWithValue) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_ROOT}api/mapcard/get_list_agency/`
            });
            return response.data.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const mapSlice = createSlice({
    name: 'map',
    initialState: {
        status: 'idle',
        isFetching: false,
        data: ""
    },
    reducers: {
        mapClearState: (state) => {
            state.status = 'idle';
            state.isFetching = false;
            state.data = "";
            return state;
        },
    },
    extraReducers: {
        [getMapAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true
        },
        [getMapAsync.fulfilled](state, action) {
            state.status = 'success'
            state.isFetching = false
            state.data = action.payload
        },
        [getMapAsync.rejected](state) {
            state.isFetching = false
            state.status = 'failed';
        }
    }
});
export const { mapClearState } = mapSlice.actions;
export default mapSlice.reducer;
import React, { useEffect, useCallback } from "react";
import { Layout, Carousel } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getBannerAsync } from "../../redux/features/feature-slice";
import TrainingScreen from "../Dialog/training-dialog";
import { getTrainingInfoAsync } from "../../redux/auth/auth-slice";

const { Header } = Layout;

export function TopBar() {
    const dispatch = useDispatch();
    const { currentAgent, isTraining } = useSelector((state) => state.auth);
    const { banners } = useSelector((state) => state.feature);

    useEffect(() => {
        dispatch(getBannerAsync({ application: "Web Agent", type: "banner", country: currentAgent?.country, "token": currentAgent?.access_token }))
    }, [dispatch, currentAgent]);


    const handleTrainingInfo = useCallback(() => {
        const data = {
            "country": currentAgent?.country,
            "token": currentAgent?.access_token,
            "is_web": true
        }
        dispatch(getTrainingInfoAsync(data));
    }, [dispatch, currentAgent])

    useEffect(() => {
        handleTrainingInfo();
    }, [handleTrainingInfo])

    return (
        <Header>
            {isTraining && <TrainingScreen />}
            <Carousel autoplay dots={false}>
                {banners?.map(item => <div><img src={`https://assets.wizall.com/image/${item.visual}`} alt="img" style={{ margin: 'auto' }} /></div>)}
            </Carousel>
        </Header>
    );
}

export default TopBar;

import React from 'react';
import { Modal, Button } from 'antd';
import { Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { showDialog } from '../../redux/dialog/dialog-slice';

export function CashoutDialog(props) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();


    const handleCashoutConfirm = async (value) => {
        props.otp(value.otp)
        dispatch(showDialog({ isCashout: false }))
    }

    return (
        <Modal
            title={props.title}
            visible={true}
            centered
            footer={null}
            closable={false}
            className="resume-transaction"
        >
            <div style={{ padding: '0px 0px' }}>
                <p style={{ padding: '0px 0px' }}>Veuillez entrer le code de validation</p>
                <div className="pincode">
                    <Form
                        name="basic"
                        form={form}
                        layout="vertical"
                        onFinish={handleCashoutConfirm}>
                        <Form.Item
                            name="otp"
                            rules={[{ required: true, message: "Veuillez saisir le code de validation" }]}>
                            <Input className="form-control" placeholder="Code de validation" style={{ textAlign: 'center' }} maxLength={9} />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ margin: '5px' }}
                                className="btn btn-primary"
                                onClick={() => dispatch(showDialog({ isCashout: false }))}> Annuler </Button>
                            <Button
                                style={{ margin: '5px' }}
                                htmlType="submit"
                                className="btn btn-secondary"> Confirmer </Button>
                        </Form.Item>
                    </Form>
                </div>

            </div>
        </Modal>
    );
}

export default CashoutDialog;

import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Col, Alert, Select, Result } from "antd";
import {
  activateAsync,
  checkStatusAsync,
  clearState,
} from "../../redux/auth/auth-slice";
import { country } from "../../utils/globalVars";

const { Option } = Select;

export function ActivatePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { background } = useSelector((state) => state.feature);

  const handleSearch = async (value) => {
    const data = {
      agent_msisdn: value.agent_msisdn,
      country: value.country ?? country(),
    };
    dispatch(checkStatusAsync(data));
  };

  const handleActivate = async (value) => {
    const data = {
      agent_msisdn: value.agent_msisdn,
      agent_new_password_confirm: value.agent_new_password_confirm,
      agent_new_pin: value.agent_new_pin,
      otp_code: value.otp_code,
      country: value.country ?? country(),
    };
    dispatch(activateAsync(data));
  };

  const goToLogin = async () => {
    dispatch(clearState());
    history.push("/login");
  };

  return (
    <>
      <div
        className="login-page"
        style={{
          backgroundImage:
            background === ""
              ? `url(assets/img/background-default.png)`
              : `url(https://assets.wizall.com/image/${background})`,
        }}
      >
        <Col
          lg={6}
          xs={16}
          style={{ marginLeft: "auto", marginRight: "200px" }}
        >
          <div className="card">
            {auth.status === "success" ? (
              <Result
                status="success"
                title="Votre compte agent a été activé avec succès."
                extra={[
                  <Button className="btn btn-secondary" onClick={goToLogin}>
                    Me connecter
                  </Button>,
                ]}
              />
            ) : (
              <Form
                style={{ margin: "40px" }}
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={auth.isSendOtp ? handleActivate : handleSearch}
              >
                <h3>ACTIVER / MOT DE PASSE OUBLIÉ</h3>
                {auth.status === "failed" && (
                  <Alert
                    message={auth.errorMessage}
                    type="error"
                    className="alert-error"
                    showIcon
                  />
                )}

                <Form.Item
                  name="country"
                  rules={[{ required: true, message: "Champs requis" }]}
                >
                  <Select placeholder="Selectionner le pays">
                    <Option value="sn">
                      <img src="assets/img/flag-sn.png" alt="img" width={20} />{" "}
                      <span>Sénégal</span>
                    </Option>
                    <Option value="ci">
                      <img src="assets/img/flag-ci.png" alt="img" width={20} />{" "}
                      <span>Cote d'ivoire</span>
                    </Option>
                    <Option value="bf">
                      <img src="assets/img/flag-bf.png" alt="img" width={20} />{" "}
                      <span>Burkina Faso</span>
                    </Option>
                    <Option value="ml">
                      <img src="assets/img/flag-ml.png" alt="img" width={20} />{" "}
                      <span>Mali</span>
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Numéro de téléphone"
                  name="agent_msisdn"
                  rules={[{ required: true, message: "Champs requis" }]}
                >
                  <Input className="form-control" />
                </Form.Item>

                {auth.isSendOtp && (
                  <div>
                    <Form.Item
                      label="Code OTP"
                      name="otp_code"
                      rules={[{ required: true, message: "Champs requis" }]}
                    >
                      <Input className="form-control" />
                    </Form.Item>

                    <Form.Item
                      label="Mot de passe"
                      name="agent_new_pin"
                      rules={[{ required: true, message: "Champs requis" }]}
                    >
                      <Input.Password className="form-control" />
                    </Form.Item>

                    <Form.Item
                      label="Confirmer le mot de passe"
                      name="agent_new_password_confirm"
                      rules={[{ required: true, message: "Champs requis" }]}
                    >
                      <Input.Password className="form-control" />
                    </Form.Item>
                  </div>
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn btn-secondary"
                    loading={auth.isFetching}
                  >
                    {" "}
                    {auth.isSendOtp ? "Valider" : "Chercher"}
                  </Button>
                </Form.Item>
                <span>
                  <Link to="/login">
                    Votre compte est déjà activé? Connectez-vous
                  </Link>
                </span>
              </Form>
            )}
          </div>
        </Col>
      </div>
    </>
  );
}

export default ActivatePage;

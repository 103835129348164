import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../../utils/api-config';
import { handleApiError } from '../../../utils/errorHandler';
import { showDialog } from '../../../redux/dialog/dialog-slice';

export const transfertSearchSenderAsync = createAsyncThunk(
    'transfert/transfertSearchSenderAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/user/search/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data
        } catch (error) {
            // return rejectWithValue(handleApiError(error.response.data))
            return rejectWithValue(dispatch(showDialog({ isError: false, errorMessage: "" })))

            // if ((error.response.data.message === "TOKEN_EXPIRED"))
            //     return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            // if (error.response.data.message === "Unauthorized")
            //     return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            // else
            //     return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const transfertSearchReceiverAsync = createAsyncThunk(
    'transfert/transfertSearchReceiverAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/international/transfer/customer/info/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: false, errorMessage: "" })))
        }
    }
);

export const transfertNoRegisterAsync = createAsyncThunk(
    'transfert/transfertNoRegisterAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/international/transfer/noregister/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const transferFeesAsync = createAsyncThunk(
    'transfert/transferFeesAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            console.log("payload ==>", payload);

            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/frais/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`
                },
                data: payload
            });
            dispatch(showDialog({ isPinCode: true }))
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const transfertSlice = createSlice({
    name: 'transfert',
    initialState: {
        status: 'idle',
        isFetching: false,
        isSuccess: false,
        isNoRegister: false,
        isNoRegisterReceiver: false,
        errorMessage: "",
        isFees: false,
        isSearchSender: false,
        isSearchReceiver: false,
        isTransfertNoRegister: false,
        fees: "",
        amount: "",
        data: "",
        sender: "",
        receiver: "",
    },
    reducers: {
        transfertClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
            state.isNoRegister = false;
            state.isNoRegisterReceiver = false;
            state.isFees = false;
            state.isSearchSender = false;
            state.isSearchReceiver = false;
            state.isTransfertNoRegister = false;
            state.errorMessage = "";
            state.sender = "";
            state.receiver = "";
            state.data = []
        },
    },
    extraReducers: {
        [transfertSearchSenderAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },

        [transfertSearchSenderAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.isSearchSender = true
            state.sender = payload
        },
        [transfertSearchSenderAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.isSearchSender = true
            state.errorMessage = payload.payload.errorMessage
            state.isNoRegister = true
            // state.isNoRegister = (payload.payload.errorMessage === "RECIPIENT_NOT_FOUND" ? true : false)
        },

        [transfertSearchReceiverAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [transfertSearchReceiverAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.isSearchReceiver = true
            state.receiver = payload
        },
        [transfertSearchReceiverAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.isSearchReceiver = true
            state.isNoRegisterReceiver = true
            // state.isNoRegister = (payload.payload.errorMessage === "RECIPIENT_NOT_FOUND" ? true : false)

        },

        [transfertNoRegisterAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [transfertNoRegisterAsync.fulfilled](state) {
            state.status = "success"
            state.isSuccess = true;
            state.isFetching = false
        },
        [transfertNoRegisterAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
            state.isTransfertNoRegister = true
        },

        [transferFeesAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [transferFeesAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.fees = payload.fees
            state.amount = payload.amount
        },
        [transferFeesAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },
    }
});

export const { transfertClearState } = transfertSlice.actions;
export default transfertSlice.reducer;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PrinterOutlined } from '@ant-design/icons';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { billClearState, billPayAsync, billSearchAsync } from "../../../redux/bills/bill-sodeci-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";

export function BillSodeciPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const billSodeci = useSelector((state) => state.billSodeci);
    const transaction = useSelector((state) => state.transaction);
    const [montant, setMontant] = useState("");
    const [police, setPolice] = useState("")
    const [phone, setPhone] = useState("")
    const [date, setDate] = useState("")
    const [numfacture, setNumfacture] = useState("")

    const handleSearch = async (value) => {
        const data = {
            "country": currentAgent.country,
            "agent_msisdn":currentAgent.phone,
            "code_facturier": "SODECI",
            "pincode": "",
            "identifiant": value.police,
            "token": currentAgent.access_token,
        }
        setPhone(value.phone_number)
        setPolice(value.police)
        dispatch(billSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": montant,
            "operation": "SODECI",
            "code": value.police,
            "frais": "",
            "pincode": "",
            "token": currentAgent.access_token,
            "agent_msisdn": currentAgent.phone
        }
        dispatch(getFeesAsync(data))
        setMontant(value.Montant);
        setDate(value.DateLimite);
        setNumfacture(value.NumFacture);
    }

    const handlePay = async (pin) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "telephone": phone,
            "receveurmsisdn": phone,
            "agent_pin": pin,
            "identifiant": police,
            "amount": montant,
            "num_facture": numfacture,
            "country": currentAgent.country,
            "token": currentAgent.access_token,
            "type_paiement": "PARTIEL", 
            "code_facturier": "SODECI", 
        }
        console.log(data)
        dispatch(billPayAsync(data))
    }

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": "ci",
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "sodeci"
        }
        dispatch(getTransactionAsync(data))
       
    }
    console.log("transaction", transaction.data)
    const getPin = (pin) => handlePay(pin)

    const resumeJson = {
        "title": "Paiement SODECI",
        "message": `Vous allez payer la facture numéro ${police} d'un montant de ${montant} F CFA expirant le ${date}.`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
         {
             title: 'Imprimer',
             render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
         }
    ];

    useEffect(() => {
        if (billSodeci.isSuccess) {
            dispatch(billClearState())
            form.resetFields()
        }
    }, [billSodeci, dispatch, form]);

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billClearState())
        };
    }, [dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="SODECI" />
            <Spin tip="Chargement..." spinning={billSodeci.isFetching || frais.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <h1>PAYER UNE FACTURE SODECI</h1>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={handleSearch}>
                                <Form.Item
                                    label="Numéro de téléphone client"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numéro de téléphone client"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Numéro DE REFERENCE"
                                    name="police"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numéro de reference"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <CustomBtn title={billSodeci.status === "success" ? "Valider" : "Chercher"} />
                                </Form.Item>

                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
                <br />
                {(billSodeci.status === "success") && (
                    <div>
                        {billSodeci.bills.Factures.length > 0 ?
                            <>
                                {billSodeci.bills.Factures.map((repo) => {
                                    return (
                                        <Col lg={12} xs={24}>
                                            <Card>
                                                <h1> Date Limite: {repo.DateLimite}</h1>
                                                <h1> Montant: {repo.Montant} FCFA</h1>
                                                <h1> Numero facture: {repo.NumFacture}</h1>
                                                
                                                <Button type="primary" onClick={() => handleFees(repo)}>Payer cette facture</Button>
                                            </Card>
                                            <br />
                                        </Col>
                                    )
                                })}
                            </> :
                            <Col lg={12} xs={24}>
                                <Card style={{ textAlign: 'center' }}>Il n'a aucune facture SODECI à payer pour ce numéro</Card>
                            </Col>}
                    </div>
                )}


            </Spin>
        </div>
    );
}
export default BillSodeciPage;
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { airtimeExpressoAsync, airtimeClearState } from "../../../redux/airtime/airtime-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { PrinterOutlined } from '@ant-design/icons';
import { API_ROOT } from "../../../utils/api-config";


export function AirtimeExpresso() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const airtime = useSelector((state) => state.airtime);
    const [montant, setMontant] = useState("");
    const [phone, setPhone] = useState("")
    const transactions = useSelector((state) => state.transaction);

    const handleFees = async (value) => {
        const data = {
            "token": currentAgent.access_token,
            "country": currentAgent.country,
            "pincode": "",
            "operation": "AIRTIME",
            "code": value.phone_number,
            "montant": value.montant
        }
        dispatch(getFeesAsync(data))
        setMontant(value.montant);
        setPhone(value.phone_number);
    }

    const handlePay = async (pin) => {
        const data = {
            "receiver_msisdn": phone,
            "amount": montant,
            "network": "EXPRESSO",
            "pincode": pin,
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        console.log(data)
        dispatch(airtimeExpressoAsync(data))
    }

    const getPin = (pin) => handlePay(pin)
    const resumeJson = {
        "title": "RECHARGE EXPRESSO",
        "message": `Vous allez effectuer une RECHARGE EXPRESSO d'un montant de ${montant} vers le numéro ${phone}.`,
        "fees": frais.fees

    }
    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "airtime_expresso",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(airtimeClearState())
        };
    }, [dispatch, form]);

    useEffect(() => {
        if (airtime.isSuccess) {
            dispatch(airtimeClearState())
            form.resetFields()
        }
    }, [airtime, dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="RECHARGE EXPRESSO" />
            <Spin tip="Chargement..." spinning={frais.isFetching || airtime.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <h1>EFFECTUER UNE RECHARGE EXPRESSO</h1>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={handleFees}>
                                <Form.Item
                                    label="Numéro de téléphone client"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numéro de téléphone client"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Montant"
                                    name="montant"
                                    rules={[{ required: true, message: "Veuillez saisir un montant" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Montant"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <CustomBtn title={airtime.status === "success" ? "Valider" : airtime.status === "success" ? "Confirmer" : "VALIDER"} />
                                </Form.Item>

                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transactions.data}
                                locale={{
                                    emptyText: (transactions.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transactions.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
                <br />

            </Spin>
        </div>
    );
}
export default AirtimeExpresso;
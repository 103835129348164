import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const getTransactionAsync = createAsyncThunk(
    'transaction/getTransactionAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/extract/transactions/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`
                },
                // data: {
                //     "agent_msisdn": "0171102365",
                //     "agent_pin": "",
                //     "country": "ci"
                // }
                data: payload
            });
            return response.data

        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        status: 'idle',
        isFetching: false,
        data: ""
    },
    reducers: {
        transactionClearState: (state) => {
            state.status = 'idle';
            state.isFetching = false;
            state.data = "";
            return state;
        },
    }, extraReducers: {
        [getTransactionAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true
        },
        [getTransactionAsync.fulfilled](state, action) {
            state.status = 'success'
            state.isFetching = false
            state.data = action.payload
        },
        [getTransactionAsync.rejected](state) {
            state.isFetching = false
            state.status = 'failed';
        }
    }
});
export const { transactionClearState } = transactionSlice.actions;
export default transactionSlice.reducer;
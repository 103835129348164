export const loadAgentState = () => {
    try {
        const serializedState = localStorage.getItem('agent');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState)
    } catch (error) {
        return undefined;
    }
}

export const saveAgentState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('agent', serializedState);
    } catch (error) { }
}

export const removeAgentState = () => {
    try {
        localStorage.removeItem('agent');
    } catch (error) {
        console.log("error:", error)
    }
}
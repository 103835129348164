import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, Spin } from "antd";
import { LoginPage } from "./pages/auth/loginPage";
import { Sidebar } from "./components/Sidebar";
import { TopBar } from "./components/TopBar";
import { LockScreen } from "./components/Dialog/lockscreen-dialog";
import { ErrorDialog } from "./components/Dialog/error-dialog";
import { SuccessDialog } from "./components/Dialog/success-dialog";
import { CollectPage } from "./pages/collect/collect-page";
import { CashinPage } from "./pages/cashin/cashin-page";
import { BillSenelecPage } from "./pages/bills/bill-sn/bill-senelec-page.jsx";
import { BillSeneauPage } from "./pages/bills/bill-sn/bill-seneau-page.jsx";
import { CashoutCostumerPage } from "./pages/cashout/cashout-customer-page";
import { CashoutCodePage } from "./pages/cashout/cashout-code-page";
import { CashoutCardPage } from "./pages/cashout/cashout-card-page";
import { CashoutProvisionPage } from "./pages/cashout/cashout-provision-page";
import { TransfertPage } from "./pages/transfert/transfert-page";
import { BillRapidoPage } from "./pages/bills/bill-sn/bill-rapido-page";
import { BillToubaCaKanamPage } from "./pages/bills/bill-sn/bill-touba-ca-kanam-page";
import { BillStartime } from "./pages/bills/bill-ml/bill-startime-page";
import { BillBboxxPage } from "./pages/bills/bill-bf/bill-bboxx-page";
import { AirtimeOrange } from "./pages/airtime/airtime-sn/airtime-orange-page";
import { AirtimeFree } from "./pages/airtime/airtime-sn/airtime-free-page";
import { AirtimeExpresso } from "./pages/airtime/airtime-sn/airtime-expresso-page";
import { AirtimePromobile } from "./pages/airtime/airtime-sn/airtime-promobile-page";
import { AirtimeOrangeBf } from "./pages/airtime/airtime-bf/airtime-orange-page";
import { AirtimeTelecelBf } from "./pages/airtime/airtime-bf/airtime-telecel-page";
import { AirtimeTelemobBf } from "./pages/airtime/airtime-bf/airtime-telemob-page";
import { AirtimeOrangeMl } from "./pages/airtime/airtime-ml/airtime-orange-page";
import { AirtimeMalitel } from "./pages/airtime/airtime-ml/airtime-malitel-page";
import { AirtimeTelecelML } from "./pages/airtime/airtime-ml/airtime-telecel-page";
import { AirtimeMtn } from "./pages/airtime/airtime-ci/airtime-mtn-page";
import { AirtimeOrangeCi } from "./pages/airtime/airtime-ci/airtime-orange-page";
import { AirtimeMoov } from "./pages/airtime/airtime-ci/airtime-moov-page";
import { BillSodeciPage } from "./pages/bills/bill-ci/bill-soceci-page";
import { BillCiePage } from "./pages/bills/bill-ci/bill-cie-page";
import { BillWoyofalPage } from "./pages/bills/bill-sn/bill-woyofal-page";
import { BillDstvPage } from "./pages/bills/bill-sn/bill-dstv-page";
import { BillOoluPage } from "./pages/bills/bill-sn/bill-oolu-page";
import { BillDerPage } from "./pages/bills/bill-sn/bill-der-page";
import { BillCampusenPage } from "./pages/bills/bill-sn/bill-campusen-page";
import { Transaction } from "./pages/transaction";
import { BillAnsambleLFJMPage } from "./pages/bills/bill-sn/bill-ansamble-lfjm-page";
import { BillAnsambleLISDPage } from "./pages/bills/bill-sn/bill-ansamble-isd-page";
import { BillDiobassPage } from "./pages/bills/bill-sn/bill-diobass-page";
import BillSomagepPage from "./pages/bills/bill-ml/bill-somegep-page";
import { BillMinteroutePage } from "./pages/bills/bill-mintroute-page";
import { ActivatePage } from "./pages/auth/activatePage";
import BillCanalPage from "./pages/bills/bill-ml/bill-canal-page";
import BillIsagoPage from "./pages/bills/bill-ml/bill-isago-page";
import { HomePage } from "./pages/home/index";
import { loadAgentState } from "./redux/loadStorage";
import { PinSettedDialog } from "./components/Dialog/pinSetted-dialog";
import { PinCodeResetPage } from "./pages/pincode-reset/index";
import BillSaarPage from "./pages/bills/bill-sn/bill-saar-page";

const { Content } = Layout;

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/activate" component={ActivatePage} />
        {/* <Route component={Dashboard} /> */}
        <PrivateRoute component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
};

const Dashboard = () => {
  const dialog = useSelector((state) => state.dialog);
  const { currentAgent } = useSelector((state) => state.auth);
  const { isFetching } = useSelector((state) => state.feature);

  return (
    <>
      <Layout>
        <TopBar />
        <Spin spinning={isFetching}>
          <Layout>
            <Sidebar />
            <Layout className="site-layout">
              <Content
                className="site-layout-background"
                style={{ margin: "24px 16px", padding: "16px" }}
              >
                <PrivateRoute exact path="/" component={HomePage} />
                <PrivateRoute path="/home" component={HomePage} />
                <PrivateRoute
                  path="/airtime-orange-sn"
                  component={AirtimeOrange}
                />
                <PrivateRoute
                  path="/airtime-orange-ci"
                  component={AirtimeOrangeCi}
                />
                <PrivateRoute path="/airtime-moov-ci" component={AirtimeMoov} />
                <PrivateRoute path="/airtime-mtn-ci" component={AirtimeMtn} />
                <PrivateRoute path="/airtime-free" component={AirtimeFree} />
                <PrivateRoute
                  path="/airtime-expresso"
                  component={AirtimeExpresso}
                />
                <PrivateRoute
                  path="/airtime-promobile"
                  component={AirtimePromobile}
                />
                <PrivateRoute
                  path="/airtime-orange-bf"
                  component={AirtimeOrangeBf}
                />
                <PrivateRoute
                  path="/airtime-telecel-bf"
                  component={AirtimeTelecelBf}
                />
                <PrivateRoute
                  path="/airtime-telemob-bf"
                  component={AirtimeTelemobBf}
                />
                <PrivateRoute
                  path="/airtime-orange-ml"
                  component={AirtimeOrangeMl}
                />
                <PrivateRoute
                  path="/airtime-malitel"
                  component={AirtimeMalitel}
                />
                <PrivateRoute
                  path="/airtime-telecel-ml"
                  component={AirtimeTelecelML}
                />
                <PrivateRoute
                  path="/bill-senelec"
                  component={BillSenelecPage}
                />
                <PrivateRoute path="/bill-rapido" component={BillRapidoPage} />
                <PrivateRoute path="/bill-seneau" component={BillSeneauPage} />
                <PrivateRoute
                  path="/bill-logement-diobass"
                  component={BillDiobassPage}
                />
                <PrivateRoute path="/bill-startimes" component={BillStartime} />
                <PrivateRoute
                  path="/bill-touba-ca-kanam"
                  component={BillToubaCaKanamPage}
                />
                <PrivateRoute path="/bill-bboxx" component={BillBboxxPage} />
                <PrivateRoute path="/bill-dstv" component={BillDstvPage} />
                <PrivateRoute
                  path="/bill-woyofal"
                  component={BillWoyofalPage}
                />
                <PrivateRoute path="/bill-oolu" component={BillOoluPage} />
                <PrivateRoute
                  path="/bill-ansamble-lfjm"
                  component={BillAnsambleLFJMPage}
                />
                <PrivateRoute
                  path="/bill-ansamble-isd"
                  component={BillAnsambleLISDPage}
                />
                <PrivateRoute
                  path="/bill-somagep"
                  component={BillSomagepPage}
                />
                <PrivateRoute
                  path="/bill-jeux-tv"
                  component={BillMinteroutePage}
                />
                <PrivateRoute path="/bill-isago" component={BillIsagoPage} />
                <PrivateRoute
                  path="/bill-campusen"
                  component={BillCampusenPage}
                />
                <PrivateRoute path="/bill-canal-ml" component={BillCanalPage} />
                <PrivateRoute path="/bill-der" component={BillDerPage} />
                <PrivateRoute path="/bill-sodeci" component={BillSodeciPage} />
                <PrivateRoute path="/bill-cie" component={BillCiePage} />
                <PrivateRoute path="/bill-saar" component={BillSaarPage} />
                <PrivateRoute
                  path="/cashout-provision"
                  component={CashoutProvisionPage}
                />
                <PrivateRoute
                  path="/cashout-card"
                  component={CashoutCardPage}
                />
                <PrivateRoute
                  path="/cashout-code"
                  component={CashoutCodePage}
                />
                <PrivateRoute
                  path="/cashout-customer"
                  component={CashoutCostumerPage}
                />
                <PrivateRoute path="/collect" component={CollectPage} />
                <PrivateRoute path="/cashin" component={CashinPage} />
                <PrivateRoute path="/transfert" component={TransfertPage} />
                <PrivateRoute
                  path="/pincode-reset"
                  component={PinCodeResetPage}
                />
                <PrivateRoute path="/transaction" component={Transaction} />
                {/* <Redirect to="/" /> */}
              </Content>
            </Layout>
          </Layout>
        </Spin>
        {dialog.isLockscreen && <LockScreen />}
        {dialog.isSuccess && <SuccessDialog />}
        {dialog.isError && <ErrorDialog />}
        {currentAgent?.pin_setted === false && <PinSettedDialog />}
      </Layout>
    </>
  );
};

const PrivateRoute = ({ ...rest }) => {
  const auth = loadAgentState();
  return auth ? <Route {...rest} /> : <Redirect to="/login" />;
};

export default App;

import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import {
    handleApiError
} from '../../utils/errorHandler';
import {
    showDialog
} from '../dialog/dialog-slice';

export const billIsagoSearchAsync = createAsyncThunk(
    'billIsage/billIsagoSearchAsync',
    async (payload, {
        dispatch,
        rejectWithValue
    }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/energia/get/info_compteur/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error.response.data", error.response.data.message)
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({
                    isLockscreen: true
                })))
            else
                return rejectWithValue(dispatch(showDialog({
                    isError: true,
                    errorMessage: handleApiError(error.response.data)
                })))
        }
    }
);


export const billIsagoPayAsync = createAsyncThunk(
    'billIsago/billIsagoPayAsync',
    async (payload, {
        dispatch,
        rejectWithValue
    }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/energia/reload/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({
                isSuccess: true,
                successMessage: "Transaction effectuée avec succès"
            }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({
                    isLockscreen: true
                })))
            else
                return rejectWithValue(dispatch(showDialog({
                    isError: true,
                    errorMessage: handleApiError(error.response.data)
                })))
        }
    }
);


export const billIsagoSlice = createSlice({
    name: 'billIsago',
    initialState: {
        status: 'idle',
        isFetching: false,
        isSuccess: false,
        data: ""
    },
    reducers: {
        billIsagoClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
        },
    },
    extraReducers: {
        [billIsagoSearchAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billIsagoSearchAsync.fulfilled](state, {
            payload
        }) {
            state.status = "success"
            state.isFetching = false
            state.data = payload
        },
        [billIsagoSearchAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [billIsagoPayAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billIsagoPayAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [billIsagoPayAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },
    }
});

export const {
    billIsagoClearState
} = billIsagoSlice.actions;
export default billIsagoSlice.reducer;
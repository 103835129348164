import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { showDialog } from '../dialog/dialog-slice';


export const getProductAsync = createAsyncThunk(
    'feature/getProductAsync',
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/features/v2/`,
                data: payload
            });
            return response.data
        } catch (error) {
        }
    }
);

export const getBackgroundAsync = createAsyncThunk(
    'feature/getBackgroundAsync',
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/features/v2/`,
                data: payload
            });
            return response.data.visual
        } catch (error) { }
    }
);

export const getBannerAsync = createAsyncThunk(
    'feature/getBannerAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/features/secure/v2/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`
                },
                data: payload
            });
            return response.data
        } catch (error) {
            console.log("error.response:", error.response);
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
        }
    }
);

export const featureSlice = createSlice({
    name: 'feature',
    initialState: {
        status: 'idle',
        isFetching: true,
        products: [],
        banners: [],
        background: "",
        error: ""
    },
    reducers: {},
    extraReducers: {
        // Products
        [getProductAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [getProductAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true;
            state.products = payload;
        },
        [getProductAsync.rejected](state) {
            state.status = 'failed'
            // state.isFetching = false
        },

        // Background
        [getBackgroundAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [getBackgroundAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.background = payload;
        },
        [getBackgroundAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        // Banners
        [getBannerAsync.pending](state) {
            state.status = 'pending'
            // state.isFetching = true;
        },
        [getBannerAsync.fulfilled](state, { payload }) {
            state.status = "success"
            // state.isFetching = false
            state.banners = payload;
        },
        [getBannerAsync.rejected](state) {
            state.status = 'failed'
            // state.isFetching = false
        }
    }
});

export default featureSlice.reducer;
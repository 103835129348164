import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { UserOutlined, DollarOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../components/Button";
import { PinCodeDialog } from "../../components/Dialog/pincode-dialog";
import { enterpriseSearchAsync, enterpriseClearState } from "../../redux/enterprise-search/enterprise-search-slice";
import { collectAsync, collectClearState } from "../../redux/collect/collect-slice";
import { getFeesAsync } from '../../redux/fees/fees-slice';
import { getTransactionAsync, transactionClearState } from "../../redux/transaction/transaction-slice";
import { PrinterOutlined } from '@ant-design/icons';
import { API_ROOT } from "../../utils/api-config";

export function CollectPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const collecte = useSelector((state) => state.collecte);
    const enterprise = useSelector((state) => state.enterprise);
    const [montant, setMontant] = useState("");
    const [receiver, setReceiver] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [comment, setComment] = useState("");
    const transactions = useSelector((state) => state.transaction);

    const handleSearch = async (value) => {
        const data = {
            "tomsisdn": value.tomsisdn,
            "pincode": "",
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(enterpriseSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.amount,
            "operation": "C_BON_AGENT",
            "pincode": "",
            "receiver": value.tomsisdn,
            "token": currentAgent.access_token
        }
        setReceiver(value.tomsisdn)
        setMontant(value.amount)
        setCustomerPhone(value.customer_phone)
        setComment(value.comment)
        dispatch(getFeesAsync(data))
    }

    const handleCashin = async (pin) => {
        const data = {
            "token": currentAgent.access_token,
            "agent_msisdn": currentAgent.phone,
            "agent_pin": pin,
            "customer_phone": customerPhone,
            "comment": comment,
            "entreprisecode": receiver,
            "amount": montant,
            "country": currentAgent.country
        }
        dispatch(collectAsync(data))
    }
    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "collecte",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
    }

    const getPin = (pin) => handleCashin(pin)

    const resumeJson = {
        "title": "Collecte",
        "message": `Vous êtes sur le point d´éffectuer une collecte d´un montant de ${montant}F CFA `,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },  
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/collect/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }

    ];
    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(collectClearState())
        };
    }, [dispatch, form]);

    useEffect(() => {
        if (enterprise.isSuccess) {
            form.setFieldsValue({ Name: enterprise.data.Name });
        }
    }, [enterprise, form]);


    useEffect(() => {
        if (collecte.isSuccess) {
            dispatch(enterpriseClearState())
            dispatch(collectClearState())
            form.resetFields()
        }
    }, [collecte, dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="Collecte" />
            <Spin tip="Chargement..." spinning={enterprise.isFetching || frais.isFetching || collecte.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={enterprise.status === "success" ? handleFees : collecte.status === "success" ? handleCashin : handleSearch}>
                                <Form.Item
                                    label="Code collecte"
                                    name="tomsisdn"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Code collecte"
                                    />
                                </Form.Item>
                                {(enterprise.status === "success") && (
                                    <div>
                                        <Form.Item
                                            label="Nom de l'entreprise"
                                            name="Name">
                                            <Input className="form-control" prefix={<UserOutlined />} disabled />
                                        </Form.Item>

                                        <>
                                            {currentAgent.country === "ci" &&
                                                <Form.Item
                                                    label="Numéro de téléphone"
                                                    name="customer_phone"
                                                    rules={[{ required: true, message: "Veuillez saisir un numéro de téléphone" }]}>
                                                    <Input className="form-control" />
                                                </Form.Item>}
                                        </>

                                        <Form.Item
                                            label="Commentaire"
                                            name="comment"
                                            rules={[{ required: true, message: "Veuillez saisir un commentaire" }]}>
                                            <Input className="form-control" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Montant"
                                            name="amount"
                                            rules={[{ pattern: new RegExp("^[0-9]+$"), message: "Montant incorrect" }, { required: true, message: 'Veuillez saisir un montant' }]}>
                                            <Input
                                                className="form-control"
                                                prefix={<DollarOutlined />}
                                                placeholder="Montant" />
                                        </Form.Item>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={enterprise.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transactions.data}
                                locale={{
                                    emptyText: (transactions.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transactions.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default CollectPage;
import React, { useState } from 'react';
import { Result, Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '../../redux/dialog/dialog-slice';

export function SuccessDialog() {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(true);
    const dialog = useSelector((state) => state.dialog);

    return (
        <Modal
            visible={isModalVisible}
            centered
            footer={null}
            closable={false}
            className="success-dialog">
            <div style={{ padding: '0px 0px', textAlign: 'center' }}>
                <Result
                    status="success"
                    title={dialog.successMessage} />
                <Button
                    style={{ margin: 'auto' }}
                    className="btn btn-primary"
                    onClick={() => { setIsModalVisible(false); dispatch(showDialog({ isSuccess: false })) }}> Fermer </Button>
            </div>

        </Modal>
    );
}

export default SuccessDialog;

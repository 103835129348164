var MinterouteData = [
    {
        "id": "1",
        "title": "itunes france",
        "value": "iTunes France",
        "image": "icon-bill-itunes.png"
    },
    {
        "id": "2",
        "title": "Apple USA",
        "value": "Apple",
        "image": "icon-bill-apple.png"
    },
    {
        "id": "3",
        "title": "Playstation",
        "value": "PlayStation Network US",
        "image": "icon-bill-plastation.png"
    },
    {
        "id": "4",
        "title": "XBOX USA",
        "value": "Xbox USA",
        "image": "icon-bill-xbox.png"
    },
    {
        "id": "5",
        "title": "Nintendo",
        "value": "Nintendo US",
        "image": "icon-bill-nintendo.png"
    },
    {
        "id": "6",
        "title": "Razor Gold",
        "value": "Razer Gold Pins",
        "image": "icon-bill-razergold.png"
    },
    {
        "id": "7",
        "title": "pubG",
        "value": "PUBG",
        "image": "icon-bill-pubg.png"

    },
    {
        "id": "8",
        "title": "Amazon USA",
        "value": "Amazon USA",
        "image": "icon-bill-amazon.png"
    },
    {
        "id": "9",
        "title": "Google Play",
        "value": "Google Play",
        "image": "icon-bill-googleplay.png"
    },
    {
        "id": "10",
        "title": "EA Play",
        "value": "EA Play",
        "image": "icon-bill-ea.png"
    },
    {
        "id": "11",
        "title": "Mc Afee",
        "value": "McAfee",
        "image": "icon-bill-mcafee.png"
    },
    {
        "id": "12",
        "title": "Minecraft",
        "value": "Minecraft",
        "image": "icon-bill-minecraft.png"
    },
]

export default MinterouteData;
// let backendHost;
// const hostname = window && window.location && window.location.hostname;

// if ((hostname === 'localhost') || (hostname === 'devagent.wizall.com')) {
//     backendHost = 'https://testagent-api.wizall.com/';
//     // backendHost = 'https://agent-api.wizall.com/';
// } else if (hostname === 'agent.wizall.com') {
//     backendHost = 'https://agent-api.wizall.com/';
// }
// export const API_ROOT = backendHost;
// export const API_ROOT = "http://10.0.15.25:9090/";
export const API_ROOT = "https://agent-api.wizall.com/";
// export const API_ROOT = "https://testagent-api.wizall.com/";
// export const API_ROOT = "https://devagent-api.wizall.com/";

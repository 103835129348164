import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../utils/api-config';
import { handleApiError } from '../utils/errorHandler';
import { showDialog } from './dialog/dialog-slice';

export const getBalanceAsync = createAsyncThunk(
    'journal/getBalanceAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/solde/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data

        } catch (error) {
            console.log("error.response:", error.response);
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const getCommissionAsync = createAsyncThunk(
    'journal/getCommissionAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/solde/commission/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data

        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Wrong credentials")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const getTransactionCountAsync = createAsyncThunk(
    'journal/getTransactionCountAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/transactions/count/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data

        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Wrong credentials")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const journalSlice = createSlice({
    name: 'journal',
    initialState: {
        status: 'idle',
        isFetching: '',
        isFetchingBalance: false,
        isFetchingCommision: false,
        isFetchingTransaction: false,
        balance: '',
        commission: '',
        transaction: '',
        error: ""
    },
    reducers: {},
    extraReducers: {
        // Balance
        [getBalanceAsync.pending](state) {
            state.status = 'pending'
            state.isFetchingBalance = true
        },
        [getBalanceAsync.fulfilled](state, action) {
            state.status = 'success'
            state.isFetchingBalance = false
            state.balance = action.payload.solde
        },
        [getBalanceAsync.rejected](state) {
            state.status = 'failed'
            state.isFetchingBalance = false
        },

        // Commission
        [getCommissionAsync.pending](state) {
            state.status = 'pending'
            state.isFetchingCommision = true
        },
        [getCommissionAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetchingCommision = false
            state.commission = payload.solde
        },
        [getCommissionAsync.rejected](state) {
            state.status = 'failed'
            state.isFetchingCommision = false
        },

        // Transaction
        [getTransactionCountAsync.pending](state) {
            state.status = 'pending'
            state.isFetchingTransaction = true;
        },
        [getTransactionCountAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetchingTransaction = false;
            state.transaction = payload
        },
        [getTransactionCountAsync.rejected](state) {
            state.status = 'failed'
            state.isFetchingTransaction = false;
        },
    }
});

export default journalSlice.reducer;
export function getEnv() {
    return ['localhost', '127.0.0.1', '//test'].find(url => document.URL.includes(url)) ? 'dev' : 'prod'
}

// export function usrProfile() {
//     return ['localhost', '127.0.0.1', '//test'].find(url => document.URL.includes(url)) ? 'dev' : 'prod'
// }


export const getNumber = (type, externalComment) =>
    matchingNumber(type, externalComment);

export const getDetails = (type, externalComment) =>
    matchingDetails(type, externalComment);

export const country = () => {
    const hostname = window && window.location && window.location.hostname;
    switch (hostname) {
        case "agent.wizall.com":
            return "sn"
        case "agent-civ.wizall.com":
            return "ci"
        case "agent-ml.wizall.com":
            return "ml"
        case "agent-bf.wizall.com":
            return "bf"
        default:
            return "sn"
    }
}

export const matchingDetails = (serviceType, externalComment = '') => {
    const data = {
        '': 'receiver',
        cashin: 'receiver',
        cashout: 'sender',
        cashout_international: 'sender',
        bon_cash: externalComment.includes('Createvoucher') ? 'receiver' : 'sender',
        // bon_cash: 'receiver',
        airtime_orange_bf: 'receiver',
        airtime_telecel_bf: 'receiver',
        airtime_telmob_bf: 'receiver',
        airtime_orange_ci: 'receiver',
        airtime_mtn_ci: 'receiver',
        airtime_tigo: 'receiver',
        airtime_expresso: 'receiver',
        airtime_moov_ci: 'receiver',
        airtime_orange: 'receiver',
        c_bon_agent: 'receiver',
        d_bon_agent: 'receiver',
        collecte: 'receiver',
        rapido: 'receiver',
        RAPIDO: 'receiver',
        oolu: 'receiver',
        reversal_collecte: 'receiver',
        reversal_cashout: `receiver`,
        reversal_cashin: `receiver`,
        reversal_bon_achat: `receiver`,
        reversal_BON_CASH: `receiver`,
        reversal_bon_cash: `receiver`,
        reversal_d_bon_agent: `receiver`,
        reversal_c_bon_agent: `receiver`,
        reversal_adjustment: `receiver`,
        reversal_: `receiver`,
        reversal_D_BON_AGENT: `receiver`,
        reversal_C_BON_AGENT: `receiver`,
        C_BON_AGENT: `receiver`,
        D_BON_AGENT: `receiver`,
        // BON_CASH: `receiver`,
        BON_CASH: externalComment.includes('Createvoucher') ? 'receiver' : 'sender',
        bon_achat: `receiver`,
        BON_ACHAT: `receiver`,
        ansamble: `receiver`,
        ansamble_isd: `receiver`,
        ANSAMBLE: `receiver`,
        ANSAMBLE_ISD: `receiver`,
        adjustment: `receiver`,
        airtime_telecel_ml: `receiver`,
        airtime_orange_ml: `receiver`,
        airtime_malitel_ml: `receiver`,
        payment_woyofal: `receiver`,
        payment_energia: `receiver`,
        payment_sde: 'receiver',
        payment_senelec: 'receiver',
        sodeci: 'receiver',
        cie: 'receiver',
        transfer_no_register: `receiver`,
        transfer_register: `receiver`,
        A2W: `sender`,
        a2w: `sender`,
        W2A: `receiver`,
        w2a: `receiver`,
    };
    return data[serviceType];
}

export const matchingNumber = (serviceTypes, externalComment = '') => {
    // console.log(userInfo);
    const data = {
        '': 'sender',
        cashin: 'sender',
        cashout: 'receiver',
        cashout_international: 'receiver',
        // cashout: `${userInfo}`,
        // bon_cash: 'sender',
        bon_cash: externalComment.includes('Createvoucher') ? 'sender' : 'receiver',
        airtime_orange_bf: 'sender',
        airtime_telecel_bf: 'sender',
        airtime_telmob_bf: 'sender',
        airtime_orange_ci: 'sender',
        airtime_mtn_ci: 'sender',
        airtime_tigo: 'sender',
        airtime_expresso: 'sender',
        airtime_moov_ci: 'sender',
        airtime_orange: 'sender',
        c_bon_agent: 'sender',
        d_bon_agent: 'sender',
        collecte: 'sender',
        rapido: 'sender',
        RAPIDO: 'sender',
        oolu: 'sender',
        reversal_collecte: 'sender',
        reversal_cashout: `sender`,
        reversal_cashin: `sender`,
        reversal_bon_achat: `sender`,
        reversal_BON_CASH: `sender`,
        reversal_bon_cash: `sender`,
        reversal_d_bon_agent: `sender`,
        reversal_c_bon_agent: `sender`,
        reversal_adjustment: `sender`,
        reversal_: `sender`,
        reversal_D_BON_AGENT: `sender`,
        reversal_C_BON_AGENT: `sender`,
        C_BON_AGENT: `sender`,
        D_BON_AGENT: `sender`,
        BON_CASH: externalComment.includes('Createvoucher') ? 'sender' : 'receiver',
        // BON_CASH: `sender`,
        bon_achat: `sender`,
        BON_ACHAT: `sender`,
        ANSAMBLE_ISD: `sender`,
        ansamble_isd: `sender`,
        ansamble: `sender`,
        ANSAMBLE: `sender`,
        adjustment: `sender`,
        airtime_telecel_ml: `sender`,
        airtime_orange_ml: `sender`,
        airtime_malitel_ml: `sender`,
        payment_woyofal: `sender`,
        payment_energia: `sender`,
        payment_sde: 'sender',
        payment_senelec: 'sender',
        sodeci: 'sender',
        cie: 'sender',
        transfer_no_register: `sender`,
        transfer_register: `sender`,
        A2W: `receiver`,
        a2w: `receiver`,
        W2A: `sender`,
        w2a: `sender`,
    };
    return data[serviceTypes];
}

export const factureType = (type) => {
    if (type.includes("payment")) { return "factures" } else
        if (type.includes("airtime")) { return "factures" } else
            if (type.includes("collecte")) { return "collect" } else
                if (type.includes("cashout")) { return "transfert" } else
                    if (type.includes("BON_CASH")) { return "transfert" } else
                        if (type.includes("cashin")) { return "transfert" } else
                            if (type.includes("C_BON_AGENT")) { return "provision" } else
                                if (type.includes("D_BON_AGENT")) { return "provision" } else
                                    if (type.includes("oolu")) { return "transfert" }
                                    else { return "transfert" }

};


export const toubaCakanamCode = (getEnv() === 'prod') ? '40448' : '22222222';
export const bboxxCode = (getEnv() === 'prod') ? '32805' : '22222222';
export const ansambleLFJMCode = (getEnv() === 'prod') ? '40448' : '22222222';
export const ansambleISDCode = (getEnv() === 'prod') ? '40003' : '22222222';
export const samambeyCode = (getEnv() === 'prod') ? '33044' : '22222222';
export const myAgroCode = (getEnv() === 'prod') ? '11011' : '22222222';
export const campusenCode = (getEnv() === 'prod') ? '22222222' : '22222222';
export const keurArameCode = (getEnv() === 'prod') ? '82727' : '22222222';
export const diobassCode = (getEnv() === 'prod') ? '44106' : '22222222';
export const derCode = (getEnv() === 'prod') ? '99999' : '22222222';
// record.type, record.external_comment, record.receiver

export const transactionType = (type, external_comment, receiver) => {

    switch (type) {
        case "cashin":
            return "DÉPOT"
        case "cashout":
            return "RETRAIT"
        case "cashout_international":
            return "RETRAIT INTERNATIONNAL"
        case "airtime_orange_ci":
            return "RECHARGE ORANGE"
        case "airtime_mtn_ci":
            return "RECHARGE MTN"
        case "airtime_tigo":
            return "RECHARGE FREE"
        case "airtime_expresso":
            return "RECHARGE EXPRESSO"
        case "airtime_moov_ci":
            return "RECHARGE MOOV"
        case "airtime_orange_bf":
            return "RECHARGE ORANGE"
        case "airtime_telecel_bf":
            return "RECHARGE TELECEL"
        case "airtime_telmob_bf":
            return "RECHARGE TELEMOB"
        case "c_bon_agent":
            return "ORDRE DE RETRAIT"
        case "canal_ml":
            return "PAIEMENT CANAL"
        case "virtual_card_mintroute":
            return "CARTE VIRTUELLE"
        case "d_bon_agent":
            return "MISE A DISPOSITION"
        case "airtime_orange":
            return "RECHARGE ORANGE"
        case "bon_achat":
            return "BON D'ACHAT"
        case "rapido":
            return "RAPIDO"
        case "RAPIDO":
            return "RAPIDO"
        case "oolu":
            return "OOLU"
        case "reversal_collecte":
            return "ANNULATION DE COLLECTE"
        case "reversal_cashout":
            return "ANNULATION D'UN RETRAIT"
        case "reversal_cashin":
            return "ANNULATION D'UN DEPOT"
        case "reversal_bon_achat":
            return "ANNULATION DE BON D'ACHAT"
        case "reversal_d_bon_agent":
            return "ANNULATION DE BON AGENT"
        case "reversal_c_bon_agent":
            return "ANNULATION D'ORDRE DE RETRAIT"
        case "reversal_adjustment":
            return "ANNULATION D'AJUSTEMENT"
        case "reversal_":
            return "ANNULATION"
        case "reversal_D_BON_AGENT":
            return "ANNULATION DE BON D'ARGENT"
        case "reversal_C_BON_AGENT":
            return "ANNULATION D'ORDRE DE RETRAIT"
        case "reversal_rechargement":
            return "ANNULATION DE RECHARGEMENT"
        case "reversal_w2a":
            return "REMBOURSEMENT BANQUE"
        case "reversal_W2A":
            return "REMBOURSEMENT BANQUE"
        case "C_BON_AGENT":
            return "ORDRE DE RETRAIT"
        case "D_BON_AGENT":
            return "MISE A DISPOSITION"
        case "rechargement":
            return "RECHARGEMENT"
        case "BON_CASH":
            if (external_comment.includes('Createvoucher'))
                return "ENVOI BON CASH"
            else
                return "RETRAIT BON CASH"
        case "BON_ACHAT":
            return "BON D'ACHAT"
        case "ansamble":
            return "ANSAMBLE-LFJM"
        case "ansamble_isd":
            return "ANSAMBLE-ISD"
        case "ANSAMBLE":
            return "ANSAMBLE-LFJM"
        case "adjustment":
            return "AJUSTEMENT"
        case "airtime_telecel_ml":
            return "RECHARGE TELECEL"
        case "airtime_orange_ml":
            return "RECHARGE ORANGE"
        case "airtime_malitel_ml":
            return "RECHARGE MALITEL"
        case "payment_woyofal":
            return "PAIEMENT WOYOFAL"
        case "payment_energia":
            return "PAIEMENT ISAGO"
        case "payment_sde":
            return "PAIEMENT SEN'EAU"
        case "payment_senelec":
            return "PAIEMENT SENELEC"
        case "sodeci":
            return "PAIEMENT SODECI"
        case "cie":
            return "PAIEMENT CIE"
        case "transfer_no_register":
            return "ENVOI BON CASH INTERNATIONAL"
        case "transfer_register":
            return "TRANSFERT D'ARGENT INTERNATIONAL"
        case "campusen":
            return "PAIEMENT CAMPUSEN"
        case "diobass":
            return "PAIEMENT DIOBASS"
        default:
            return type
    }
}

export const matchingTypes = (externalComment = '', externalInfo = '') => {
    return {
        cashin: 'DEPOT',
        bon_cash: externalComment.includes('Createvoucher')
            ? 'ENVOI BON CASH'
            : 'RETRAIT BON CASH',
        cashout: 'RETRAIT',
        cashout_international: 'RETRAIT INTERNATIONNAL',
        airtime_orange_ci: 'RECHARGE ORANGE',
        airtime_mtn_ci: 'RECHARGE MTN',
        airtime_tigo: 'RECHARGE FREE',
        airtime_expresso: 'RECHARGE EXPRESSO',
        airtime_moov_ci: 'RECHARGE MOOV',
        airtime_orange_bf: 'RECHARGE ORANGE',
        airtime_telecel_bf: 'RECHARGE TELECEL',
        airtime_telmob_bf: 'RECHARGE TELEMOB',
        c_bon_agent: 'ORDRE DE RETRAIT',
        d_bon_agent: 'MISE A DISPOSITION',
        airtime_orange: 'RECHARGE ORANGE',
        bon_achat: `BON D'ACHAT`,
        // collecte: externalInfo.includes(samambeyMagicNumber())
        //   ? `COLLECTE SAMAMBEY`
        //   : 'COLLECTE ',
        // eslint-disable-next-line no-dupe-keys
        // eslint-disable-next-line no-nested-ternary
        collecte: externalInfo.includes(

        )
            ? country === 'ml'
                ? `COLLECTE N'GASENE`
                : `COLLECTE SAMAMBEY`
            : 'COLLECTE ',
        // collecte: 'COLLECTE',
        rapido: 'RAPIDO',
        RAPIDO: 'RAPIDO',
        oolu: 'OOLU',
        reversal_collecte: `ANNULATION DE COLLECTE`,
        reversal_cashout: `ANNULATION D'UN RETRAIT`,
        reversal_cashin: `ANNULATION D'UN DEPOT`,
        reversal_bon_achat: `ANNULATION DE BON D'ACHAT`,
        reversal_BON_CASH: `ANNULATION DE BON D'ACHAT`,
        reversal_bon_cash: `ANNULATION DE BON D'ARGENT`,
        reversal_d_bon_agent: `ANNULATION DE BON AGENT`,
        reversal_c_bon_agent: `ANNULATION D'ORDRE DE RETRAIT`,
        reversal_adjustment: `ANNULATION D'AJUSTEMENT`,
        reversal_: `ANNULATION`,
        reversal_D_BON_AGENT: `ANNULATION DE BON D'ARGENT`,
        reversal_C_BON_AGENT: `ANNULATION D'ORDRE DE RETRAIT`,
        C_BON_AGENT: `ORDRE DE RETRAIT`,
        D_BON_AGENT: `MISE A DISPOSITION`,
        BON_CASH: externalComment.includes('Createvoucher')
            ? 'ENVOI BON CASH'
            : 'RETRAIT BON CASH',
        BON_ACHAT: `BON D'ACHAT`,
        ansamble: `ANSAMBLE-LFJM`,
        ANSAMBLE: `ANSAMBLE-LFJM`,
        ansamble_isd: `ANSAMBLE-ISD`,
        ANSAMBLE_ISD: `ANSAMBLE-ISD`,
        adjustment: `AJUSTEMENT`,
        airtime_telecel_ml: `RECHARGE TELECEL`,
        airtime_orange_ml: `RECHARGE ORANGE`,
        airtime_malitel_ml: `RECHARGE MALITEL`,
        payment_woyofal: `PAIEMENT WOYOFAL`,
        payment_energia: `PAIEMENT ISAGO`,
        payment_sde: `PAIEMENT SEN'EAU`,
        payment_senelec: 'PAIEMENT SENELEC',
        sodeci: 'PAIEMENT SODECI',
        cie: 'PAIEMENT CIE',
        transfer_no_register: `ENVOI BON CASH INTERNATIONAL`,
        transfer_register: `TRANSFERT D'ARGENT INTERNATIONAL`,
        A2W: `BACI VERS WIZALL`,
        a2w: `BACI VERS WIZALL`,
        W2A: `WIZALL VERS BACI`,
        w2a: `WIZALL VERS BACI`,
        virtual_card_mintroute: `Carte virtuelle`
    };
}
export function matchingCoutry() {
    return {
        sn: `Sénégal`,
        ci: `Côte d'Ivoire`,
        ml: `Mali`,
        bf: `Burkina Faso`,
    };
}



import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const getFeesAsync = createAsyncThunk(
    'airtime/getFeesAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/frais/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`
                },
                data: payload
            });
            dispatch(showDialog({ isPinCode: true }))
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const feesSlice = createSlice({
    name: 'fees',
    initialState: {
        status: 'idle',
        isFetching: false,
        fees: "",
        amount: "",
    },
    reducers: {
    },
    extraReducers: {
        [getFeesAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [getFeesAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.fees = payload.fees
            state.amount = payload.amount
        },
        [getFeesAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },
    }
});

export default feesSlice.reducer;
import React from "react";
import { Modal } from 'antd';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { saveTrainingInfoAsync } from "../../redux/auth/auth-slice";


export function TrainingScreen() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);

    const handleTrainingSave = () => {
        const data = {
            "country": currentAgent?.country,
            "token": currentAgent?.access_token,
            // "is_web": true
        }
        dispatch(saveTrainingInfoAsync(data));
    }


    return (
        <Modal
            visible={true}
            centered
            footer={null}
            closable={false}
            mask
            maskClosable={false}
            maskStyle={{ backgroundColor: '#50B5C7F5' }}
            width={1000}>
            <Carousel>
                <div>
                    <img src="./assets/img/training/training-1.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-2.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-3.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-4.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-5.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-6.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-7.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-8.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-9.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-10.jpg" alt="wizall-img" />
                </div>
                <div>
                    <img src="./assets/img/training/training-11.jpg" alt="wizall-img" />
                </div>
                <div onClick={() => handleTrainingSave()}>
                    <img src="./assets/img/training/training-12.jpg" alt="wizall-img" />
                </div>
            </Carousel>
        </Modal>
    );
}
export default TrainingScreen;

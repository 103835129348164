import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table ,Button} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from "../../../redux/fees/fees-slice";
import { billBboxxAsync, billBboxxClearState } from "../../../redux/bills/bill-bboxx-slice";
import { bboxxCode } from "../../../utils/globalVars";
import { API_ROOT } from "../../../utils/api-config";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { PrinterOutlined } from '@ant-design/icons';


export function BillBboxxPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const billBboxx = useSelector((state) => state.billBboxx);
    const [montant, setMontant] = useState("");
    const [phone, setPhone] = useState("")
    const transactions= useSelector((state) => state.transaction);
    const [cni, setCni] = useState("")

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.montant,
            "operation": "Collecte",
            "pincode": "",
            "receiver": value.phone_number,
            "token": currentAgent.access_token
        }
        setPhone(value.phone_number)
        dispatch(getFeesAsync(data))
        setMontant(value.montant)
        setCni(value.cni_number)
    }

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "collecte"
        }
        dispatch(getTransactionAsync(data))
       
    }

    const handlePay = async (pin) => {
        const data = {
            "amount": montant,
            "client_number": phone,
            "cni_number": cni,
            "comment": phone + " " + cni,
            "country": currentAgent.country,
            "entreprisecode": bboxxCode,
            "pincode": pin,
            "token": currentAgent.access_token
        }
        dispatch(billBboxxAsync(data))
    }
    const getPin = (pin) => handlePay(pin)

    const resumeJson = {
        "title": "Payment carte Bboxx",
        "message": `Vous allez effectuer un payement BBOXX d'un montant de ${montant}F CFA`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (billBboxx.isSuccess) {
            dispatch(billBboxxClearState())
            form.resetFields()
        }
    }, [billBboxx, dispatch, form]);

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billBboxxClearState())
        };
    }, [dispatch, form]);


    return (
        <div className="default-page" >
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="Payement Bboxx" />
            <Spin tip="Chargement..." spinning={frais.isFetching || billBboxx.isFetching} >
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={billBboxx.status === "success" ? handlePay : handleFees}
                            >

                                <Form.Item
                                    label="Numéro du client"
                                    name="cni_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero du client"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Numero de carte Bboxx"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de carte Bboxx"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Montant"
                                    name="montant"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]} >
                                    <Input
                                        className="form-control"
                                        placeholder="Montant" />
                                </Form.Item>

                                <Form.Item>
                                    <CustomBtn title={frais.status === "success" ? "Valider" : billBboxx.status === "success" ? "Confirmer" : "Valider"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                               dataSource={transactions.data}
                               locale={{
                                   emptyText: (transactions.data) ?
                                       null
                                       : (
                                           <Button
                                               onClick={() => handleTransaction()}
                                               size="large"
                                               className="btn btn-secondary">
                                               {"Afficher l'historique des transactions"}
                                           </Button>
                                       )
                               }}
                               loading={transactions.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillBboxxPage;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { PrinterOutlined,UserOutlined, DollarOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from "../../../redux/fees/fees-slice";
import { derCode } from "../../../utils/globalVars";
import { collectAsync, collectClearState } from "../../../redux/collect/collect-slice";
import { billDerSearchAsync, billDerClearState } from "../../../redux/bills/bill-der-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";

export function BillDerPage () {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const transactions = useSelector((state) => state.transaction);
    const collect = useSelector((state) => state.collecte);
    const billDer = useSelector((state) => state.billDer);
    const [montant, setMontant] = useState("");
    const [cni, setCni] = useState("");
    const [phone_number, setPhone_number] = useState("");
  

    const handleSearchDer = async (value) => {
        const data = {
            "agent_msisdn" : currentAgent.phone,
            "agent_pin": "",
            "phone_number":value.phone_number, 
            "cni_number":value.cni,
            "country": currentAgent.country,
            "token": currentAgent.access_token
        } 
        dispatch(billDerSearchAsync(data))
    
        setPhone_number(value.phone_number)
        setCni(value.cni)
    
     
    }
   
    const partiel = billDer?.data.is_partial;
   const RestantPayer = (billDer?.data.remaining_to_pay)


  
    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.montant,
            "operation": "Collecte",
            "pincode": "",
            "token": currentAgent.access_token,
            "receiver": phone_number

        }
        
        setMontant(value.montant)
     
        if ( parseInt(billDer?.data.remaining_to_pay) > parseInt(value.montant) && parseInt(billDer?.data.min_amount_to_pay) < parseInt(value.montant)) 
       { dispatch(getFeesAsync(data))}
        else  if (parseInt(billDer?.data.min_amount_to_pay) > parseInt(value.montant) && parseInt(billDer?.data.remaining_to_pay) <= parseInt(value.montant))
        { dispatch(getFeesAsync(data))}
        else if ( parseInt(billDer?.data.remaining_to_pay) < parseInt(value.montant) ) { alert("Veillez saisir un montant minumum ou égal à "+ billDer?.data.remaining_to_pay) }
       else { alert("Veillez saisir un montant maximum ou égal à "+ billDer?.data.min_amount_to_pay) }
   
    }

    const handleCollect = async (pin) => {
        const data = { 
            "agent_msisdn" : currentAgent.phone, 
            "agent_pin": pin, 
            "amount": montant, 
            "comment": phone_number+";"+cni+";"+RestantPayer, 
            "entreprisecode" : derCode, 
            "cni_number": cni, 
            "client_number": phone_number ,
            "token": currentAgent.access_token
        } 
        dispatch(collectAsync(data))  
        
    }

    const getPin = (pin) => handleCollect(pin)

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "collecte"
        }
        dispatch(getTransactionAsync(data))
       
    }

     form.setFieldsValue({
        firstname: billDer?.data.prenom,
        lastname: billDer?.data.nom,
        restant: RestantPayer
    });
    const resumeJson = {
        "title": "Paiement DER",
        "message": `Vous allez effectuer un payement DER d'un montant de ${montant}F CFA `,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (collect.isSuccess) {
            dispatch(collectClearState())
            dispatch(billDerClearState())
            form.resetFields()
        }
    }, [collect, dispatch, form]);

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billDerClearState())
        };
    }, [dispatch, form]);

    return (
        
        <div className="default-page">
             {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
         
            <PageHeader title="Paiement DER" />
            <Spin tip="Chargement..." spinning={billDer.isFetching || frais.isFetching || collect.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish=
                                {billDer.status === "success" ? handleFees : collect.status === "success" ? handleCollect : handleSearchDer}
                                >
                               <Form.Item
                                    label="Numero de Téléphone"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de Téléphone"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Numero de la carte d'identité"
                                    name="cni"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de la carte d'identité"
                                    />
                                </Form.Item>
                                {(billDer.status === "success") && (
                                    <div>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Nom "
                                                    name="firstname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item 
                                                label="Prénom " 
                                                name="lastname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            {(partiel === true) && (
                                                     <div>
                                          
                                                <Form.Item
                                                    label="Restant à payer"
                                                    name="restant"
                                                   
                                                    >
                                                    <Input className="form-control" prefix={<DollarOutlined />} disabled />
                                                </Form.Item>
                                           </div>
                                            )}
                                        </Row>
                                        <Form.Item
                                            label="Montant"
                                            name="montant"
                                            rules={[{ pattern: new RegExp("^[0-9]+$"), message: "Montant incorrect" }, { required: true, message: 'Veuillez saisir un montant' }]}>
                                            
                                            <Input
                                                className="form-control"
                                                prefix={<DollarOutlined />}
                                                placeholder="Montant" />
                                        </Form.Item>

                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={billDer.status === "success" ? "Valider" : collect.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transactions.data}
                                locale={{
                                    emptyText: (transactions.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transactions.isFetching}
                                columns={columns} />

                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    
    );
}
export default BillDerPage;


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';


export const carteClientSearchAsync = createAsyncThunk(
    'billStartime/carteClientSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/startimes/subscribers_info/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload
            })
            dispatch(showDialog({ isCashout: true }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const catalogSearchAsync = createAsyncThunk(
    'billStartime/catalogueSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/startimes/catalog/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload

            })

            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const packagesSearchAsync = createAsyncThunk(
    'billStartime/packagesSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/startimes/packages/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload
            })
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const rechargingAsync = createAsyncThunk(
    'billStartime/rechargingAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/startimes/recharging/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const billStartimesSlice = createSlice({
    name: 'billStartimesSlice',
    initialState: {
        status: 'idle',
        isFetching: false,
        isSuccess: false,
        carteClient: {
            status: 'idle',
            isFetching: false,
            isSuccess: false,
        },
        catalogue: {
            status: 'idle',
            isFetching: false,
            isSuccess: false,
            data: []
        },
        packages: {
            status: 'idle',
            isFetching: false,
            isSuccess: false,
            data: []
        },
        recharging: {
            status: 'idle',
            isFetching: false,
            isSuccess: false,
            data: []
        }
    },
    reducers: {
        billStartimesClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
            state.carteClient = {
                status: 'idle',
                isFetching: false,
                isSuccess: false,
                data: []
            }
            state.catalogue = {
                status: 'idle',
                isFetching: false,
                isSuccess: false,
                data: []
            }
            state.packages = {
                status: 'idle',
                isFetching: false,
                isSuccess: false,
                data: []
            }
            state.recharging = {
                status: 'idle',
                isFetching: false,
                isSuccess: false,
                data: []
            }
        },
    },
    extraReducers: {
        [packagesSearchAsync.pending](state) {
            state.packages.status = 'pending'
            state.packages.isFetching = true;
        },
        [packagesSearchAsync.fulfilled](state, { payload }) {
            state.packages.status = "success"
            state.packages.isFetching = false
            state.packages.isSuccess = true
            state.packages.data = payload
        },
        [packagesSearchAsync.rejected](state) {
            state.packages.status = 'failed'
            state.packages.isFetching = false
        },

        [catalogSearchAsync.pending](state) {
            state.catalogue.status = 'pending'
            state.catalogue.isFetching = true;
        },
        [catalogSearchAsync.fulfilled](state, { payload }) {
            state.catalogue.status = "success"
            state.catalogue.isFetching = false
            state.catalogue.isSuccess = true
            state.catalogue.data = payload

        },
        [catalogSearchAsync.rejected](state) {
            state.catalogue.status = 'failed'
            state.catalogue.isFetching = false
        },

        [carteClientSearchAsync.pending](state) {
            state.carteClient.status = 'pending'
            state.carteClient.isFetching = true;
        },
        [carteClientSearchAsync.fulfilled](state, { payload }) {
            state.carteClient.status = "success"
            state.carteClient.isFetching = false
            state.carteClient.data = payload
        },
        [carteClientSearchAsync.rejected](state) {
            state.carteClient.status = 'failed'
            state.carteClient.isFetching = false
        },
        [rechargingAsync.pending](state) {
            state.recharging.status = 'pending'
            state.recharging.isFetching = true;
        },
        [rechargingAsync.fulfilled](state, { payload }) {
            state.recharging.status = "success"
            state.recharging.isFetching = false
            state.isSuccess = true;
            state.recharging.data = payload
        },
        [rechargingAsync.rejected](state) {
            state.recharging.status = 'failed'
            state.recharging.isFetching = false
        },

    }
});

export const { billStartimesClearState } = billStartimesSlice.actions;
export default billStartimesSlice.reducer;


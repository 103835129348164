import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {PrinterOutlined } from '@ant-design/icons';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { ooluPayAsync, ooluClearState } from "../../../redux/bills/bill-oolu-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";

export function BillOoluPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const oolu = useSelector((state) => state.billOolu);
    const [mont, setMont] = useState("");
    const [phone_number, setPhone_number] = useState("")
    const transaction = useSelector((state) => state.transaction);
    const [numOolu, setNumOolu] = useState("")

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.montant,
            "operation": "OOLU",
            "pincode": "",
            "receiver": value.number,
            "token": currentAgent.access_token
        }

        dispatch(getFeesAsync(data))
        setMont(value.montant)
        setNumOolu(value.Oolu_number)
        setPhone_number(value.number)

    }


    const handleBillOolu = async (pin) => {
        const data = {
            "account_number": numOolu,
            "agent_pin": pin,
            "amount": mont,
            "country": currentAgent.country,
            "customer_phone": phone_number,
            "token": currentAgent.access_token
        }
        dispatch(ooluPayAsync(data))

    }

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "collecte"
        }
        dispatch(getTransactionAsync(data))
       
    }

    const getPin = (pin) => handleBillOolu(pin)
    form.setFieldsValue({
        frais: frais.fees,
    });

    const resumeJson = {
        "title": "Payement Carte Oolu",
        "message": `Vous allez effectuer une RECHARGE OOLU d'un montant de ${mont}F CFA vers le numéro ${phone_number}`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            responsive: ['lg'],
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (oolu.isSuccess) {
            dispatch(ooluClearState())
            form.resetFields()
        }
    }, [oolu, dispatch, form]);

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(ooluClearState())
        };
    }, [dispatch, form]);
    return (
        <div className="default-page" >
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="RECHARGE OOLU" />
            <Spin tip="Chargement..." spinning={frais.isFetching || oolu.isFetching} >
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={oolu.status === "success" ? handleBillOolu : handleFees}>

                                <Form.Item
                                    label="Numéro du client"
                                    name="number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero du client"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Numero de la carte Oolu"
                                    name="Oolu_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de la carte Oolu"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Montant"
                                    name="montant"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]} >
                                    <Input
                                        className="form-control"
                                        placeholder="Montant"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <CustomBtn title="Valider" />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                    <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillOoluPage;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const billDerSearchAsync = createAsyncThunk(
    'der/billDerSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/der/customer/details/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isCashout: true }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

// export const cashoutProvisionConfirmAsync = createAsyncThunk(
//     'cashoutAgentProvision/cashoutProvisionConfirmAsync',
//     async (payload, { dispatch, rejectWithValue }) => {
//         try {
//             const response = await axios({
//                 method: 'POST',
//                 url: `${API_ROOT}api/cashout/confirm/`,
//                 headers: {
//                     'Content-Type': 'application/json',
//                     "Authorization": `Bearer ${payload.token}`,
//                     'App-Platform': '1'
//                 },
//                 data: payload
//             });
//             dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
//             return response.data
//         } catch (error) {
//             if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
//                 return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
//             else
//                 return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
//         }
//     }
// );

export const derSlice = createSlice({
    name: 'der',
    initialState: {
        status: 'idle',
        isFetching: false,
        isSuccess: false,
        data: "",
    },
    reducers: {
        billDerClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
            state.data = "";
        },
    },
    extraReducers: {
        [billDerSearchAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billDerSearchAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.data = payload

        },
        [billDerSearchAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        // [cashoutProvisionConfirmAsync.pending](state) {
        //     state.status = 'pending'
        //     state.isFetching = true;
        // },
        // [cashoutProvisionConfirmAsync.fulfilled](state) {
        //     state.status = "success"
        //     state.isSuccess = true;
        //     state.isFetching = false
        // },
        // [cashoutProvisionConfirmAsync.rejected](state) {
        //     state.status = 'failed'
        //     state.isFetching = false
        // },
    }
});

export const { billDerClearState } = derSlice.actions;
export default derSlice.reducer;
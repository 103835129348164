import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button, DatePicker } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { PrinterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { campusenClearState, campusenPayAsync, campusenSearchAsync } from "../../../redux/bills/bill-campusen-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";
import moment from 'moment'

export function BillCampusenPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const billCampusen = useSelector((state) => state.billCampusen);
    const [receiver, setReceiver] = useState("");
    const [date, setDate] = useState("");
    const transaction = useSelector((state) => state.transaction);
    const [ine, setIne] = useState("");

    function handleDate(value) {
        var dateNaissance = moment(value).format('YYYY-MM-DD');
        setDate(dateNaissance)
    }
    const handleSearch = async (value) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "agent_pin": "",
            "numero_ine": value.numero_ine,
            "date_naissance": date,
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(campusenSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": billCampusen?.data.total_amount,
            "operation": "",
            "pincode": "",
            "receiver": value.phone_number,
            "token": currentAgent.access_token
        }
        setReceiver(value.phone_number)
        setIne(value.numero_ine)
        setDate(value.date)
        dispatch(getFeesAsync(data))
    }

    const handleReload = async (pin) => {
        const data = {
            "token": currentAgent.access_token,
            "phone": receiver,
            "agent_pin": pin,
            "agent_msisdn": currentAgent.phone,
            "comment": "comment",
            "numero_ine": ine,
            "fee": billCampusen?.data.fee,
            "total_amount": billCampusen?.data.total_amount,
            "country": currentAgent.country,
            "amount": billCampusen?.data.amount,
            "date_naissance": billCampusen?.data.date_naissance,
        }
        dispatch(campusenPayAsync(data))
    }

    const getPin = (pin) => handleReload(pin)

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "W2A",
            "recever": "campusen"
        }
        dispatch(getTransactionAsync(data))
    }

    form.setFieldsValue({
        firstname: billCampusen?.data.prenom,
        lastname: billCampusen?.data.nom,
        universite: billCampusen?.data.universite,
        montant: billCampusen?.data.total_amount
    });

    const resumeJson = {
        "title": "Paiement Campusen",
        "message": `Vous allez effectuer un paiement  d'un montant de ${billCampusen?.data.total_amount}F CFA `,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            responsive: ['lg'],
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];


    useEffect(() => {
        if (billCampusen.isSuccess) {
            dispatch(campusenClearState())
            form.resetFields()
        }
    }, [billCampusen, dispatch, form]);

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
        };
    }, [dispatch, form]);


    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="CAMPUSEN" />
            <Spin tip="Chargement..." spinning={billCampusen.isFetching || frais.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={billCampusen.status === "success" ? handleFees : billCampusen.status === "success" ? handleReload : handleSearch}>
                                <Form.Item
                                    label="Numero du client"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero du client"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Identifiant nationnal d'étudiant(INE)"
                                    name="numero_ine"
                                    rules={[{ required: true, message: "Veuillez saisir votre INE" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Identifiant nationnal d'étudiant(INE)"
                                    />
                                </Form.Item>
                                <Form.Item label="Date de naissance" name="Date" style={{ width: "100%" }}>
                                    <DatePicker className="form-control" style={{ width: "100%" }} onChange={handleDate} />
                                </Form.Item>

                                {(billCampusen.status === "success") && (
                                    <div>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Nom de l'étudiant"
                                                    name="lastname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="prenom de l'etudiant"
                                                    name="firstname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Universite"
                                                    name="universite">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="montant"
                                                    name="montant">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={billCampusen.status === "success" ? "Valider" : billCampusen.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillCampusenPage;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table ,Button} from 'antd';
import { UserOutlined, DollarOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../components/Button";
import { PinCodeDialog } from "../../components/Dialog/pincode-dialog";
import { cardSearchAsync, cardClearState } from "../../redux/card-search/card-search-slice";
import CashoutDialog from "../../components/Dialog/cashout-dialog";
import { cashoutCardAsync, cashoutCardConfirmAsync, cashoutCardClearState } from "../../redux/cashout/cashout-card-slice";
import { showDialog } from "../../redux/dialog/dialog-slice";
import { getTransactionAsync, transactionClearState } from "../../redux/transaction/transaction-slice";
import { PrinterOutlined } from '@ant-design/icons';
import { API_ROOT } from "../../utils/api-config";

export function CashoutCardPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [pincode, setPincode] = useState("");
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const card = useSelector((state) => state.card);
    const cashoutCard = useSelector((state) => state.cashoutCard);
    const [montant, setMontant] = useState("");
    const [numCard, setNumCard] = useState(0);
    const transaction = useSelector((state) => state.cashoutCard);
    const transactions = useSelector((state) => state.transaction);
   

    const handleSearch = async (value) => {
        const data = {
            "number_card": value.number_card,
            "pincode": "",
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(cardSearchAsync(data))
    }

    const showPinCodeDialog = async (value) => {
        dispatch(showDialog({ isPinCode: true }))
        setMontant(value.amount);
        setNumCard(value.number_card);
    }

    const handleCashout = async (pin) => {
        const data = {
            "token": currentAgent.access_token,
            "number_card": numCard,
            "pincode": pin,
            "amount": montant,
            "country": currentAgent.country
        }
        dispatch(cashoutCardAsync(data))
        setPincode(pin)
    }


    const handleCashoutConfirm = async (otp) => {
        const data = {
            "pincode": pincode,
            "otp": otp,
            "transaction_id": transaction.transactionId,
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        console.log(data)
        dispatch(cashoutCardConfirmAsync(data))
    }

    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "nfcCard",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
    }

    const getPin = (pin) => handleCashout(pin)
    const getOtp = (otp) => handleCashoutConfirm(otp)


    form.setFieldsValue({
        firstname: card?.data.first_name,
        lastname: card?.data.last_name
    });


    const resumeJson = {
        "title": "Retrait carte",
        "message": `Vous allez effectuer un Retrait d'un montant de ${montant} F CFA vers le numéro de carte ${numCard}. Veuillez saisir votre code PIN pour continuer  `,
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/transfert/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (cashoutCard.isSuccess) {
            dispatch(cardClearState())
            dispatch(cashoutCardClearState())
            form.resetFields()
        }
    }, [cashoutCard, dispatch, form]);

    useEffect(() => {
        return () => {
          dispatch(transactionClearState())
            dispatch(cashoutCardClearState())
        };
    }, [dispatch]);


    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            {dialog.isCashout && <CashoutDialog title="RETRAIT NFC" otp={getOtp} />}
            <PageHeader title="Retrait NFC" />
            <Spin tip="Chargement..." spinning={card.isFetching || cashoutCard.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={card.status === "success" ? showPinCodeDialog : cashoutCard.status === "success" ? handleCashoutConfirm : handleSearch}>
                                <Form.Item
                                    label="Numero de carte"
                                    name="number_card"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de carte wizall du client"
                                    />
                                </Form.Item>
                                {(card.status === "success") && (
                                    <div>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Nom client"
                                                    name="firstname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Prénom client" name="lastname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            label="Montant"
                                            name="amount"
                                            rules={[{ pattern: new RegExp("^[0-9]+$"), message: "Montant incorrect" }, { required: true, message: 'Veuillez saisir un montant' }]}>
                                            <Input
                                                className="form-control"
                                                prefix={<DollarOutlined />}
                                                placeholder="Montant" />
                                        </Form.Item>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={card.status === "success" ? "Valider" : cashoutCard.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table    
                             dataSource={transactions.data}
                                locale={{
                                    emptyText: (transactions.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transactions.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default CashoutCardPage;
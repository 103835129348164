import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const collectAsync = createAsyncThunk(
    'collect/collectAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}/api/collecte/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "La transaction a été effectuée avec succès." }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);



export const collectSlice = createSlice({
    name: 'collecte',
    initialState: {
        status: 'idle',
        isFetching: false,
        isSuccess: false,

    },
    reducers: {
        collectClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
        },
    },
    extraReducers: {
        [collectAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [collectAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true;
        },
        [collectAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

    }
});

export const { collectClearState } = collectSlice.actions;
export default collectSlice.reducer;
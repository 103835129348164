import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { billSeneauSearchAsync, billSeneauPayAsync, billSeneauClearState } from "../../../redux/bills/bill-seneau-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { PrinterOutlined } from '@ant-design/icons';
import { API_ROOT } from "../../../utils/api-config";

export function BillSeneauPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const billSeneau = useSelector((state) => state.billSeneau);
    const cashout = useSelector((state) => state.cashoutProvision);
    const [montant, setMontant] = useState("");
    const [referenceClient, setReferenceClient] = useState("")
    const [phone, setPhone] = useState("")
    const [date, setDate] = useState("")
    const [referenceFacture, setReferenceFacture] = useState("")
    const transaction = useSelector((state) => state.transaction);


    const handleSearch = async (value) => {
        const data = {
            "country": currentAgent.country,
            "phone": value.phone_number,
            "pincode": "",
            "reference_client": value.referenceClient,
            "token": currentAgent.access_token,
        }
        setReferenceClient(referenceClient)
        setPhone(value.phone_number)
        dispatch(billSeneauSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.montant,
            "operation": "SDE",
            "code": value.reference_client,
            "frais": "",
            "pincode": "",
            "token": currentAgent.access_token,
            "agent_msisdn": currentAgent.phone
        }
        dispatch(getFeesAsync(data))
        setReferenceClient(value.reference_client)
        setMontant(value.montant);
        setDate(value.date_echeance);
        setReferenceFacture(value.reference_facture);
    }

    const handlePay = async (pin) => {
        const data = {
            "reference_client": referenceClient,
            "reference_facture": referenceFacture,
            "phone": phone,
            "receveurmsisdn": phone,
            "montant": montant,
            "pincode": pin,
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(billSeneauPayAsync(data))
    }

    const getPin = (pin) => handlePay(pin)

    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "payment_sde",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
        console.log("transaction", transaction)
    }

    const resumeJson = {
        "title": "Paiement SENELEC",
        "message": `Vous allez payer la facture numéro ${referenceClient} d'un montant de ${montant} F CFA expirant le ${date}.`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (billSeneau.isSuccess) {
            dispatch(billSeneauClearState())
            form.resetFields()
        }
    }, [billSeneau, dispatch, form]);

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billSeneauClearState())
        };
    }, [dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="SEN'EAU" />
            <Spin tip="Chargement..." spinning={billSeneau.isFetching || frais.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <h1>PAYER UNE FACTURE SEN'EAU</h1>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={handleSearch}>
                                <Form.Item
                                    label="Numéro de téléphone client"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numéro de téléphone client"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Numéro compte de contrat"
                                    name="referenceClient"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numéro compte de contrat"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <CustomBtn title={billSeneau.status === "success" ? "Valider" : cashout.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>

                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                       <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
                <br />
                {(billSeneau.status === "success") && (
                    <div>
                        {billSeneau.data.length > 0 ?
                            <>
                                {billSeneau.data.map((repo) => {
                                    return (
                                        <Col lg={12} xs={24}>
                                            <Card>
                                                <h1> Date écheance: {repo.date_echeance}</h1>
                                                <h1> Montant: {repo.montant} FCFA</h1>
                                                <h1> Numero facture: {repo.reference_facture}</h1>
                                                <h1> Frais: {repo.frais} FCFA</h1>
                                                <h1> Reference client: {repo.reference_client}</h1>
                                                <h1> Total: {repo.total} FCFA</h1>
                                                <Button type="primary" onClick={() => handleFees(repo)}>Payer cette facture</Button>
                                            </Card>
                                            <br />
                                        </Col>
                                    )
                                })}
                            </> :
                            <Col lg={12} xs={24}>
                                <Card style={{ textAlign: 'center' }}>Il n'a aucune facture SEN'EAU à payer pour ce numéro</Card>
                            </Col>}
                    </div>
                )}
            </Spin>
        </div>
    );
}
export default BillSeneauPage;
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Select,Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { carteClientSearchAsync, catalogSearchAsync, packagesSearchAsync, rechargingAsync, billStartimesClearState } from "../../../redux/bills/bill-startimes-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";
import { PrinterOutlined } from '@ant-design/icons';

export function BillStartime() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const billStartimes = useSelector((state) => state.billStartimes);
    const [montant, setMontant] = useState("");
    const [receiver, setReceiver] = useState("");
    const { Option } = Select;
    const transaction = useSelector((state) => state.transaction);


    const handleChange = (value) => setMontant(value);

    const handlesubscribers_info = async (val) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "agent_pin": "",
            "country": currentAgent.country,
            "service_code": val.service_code,
            "token": currentAgent.access_token
        }
        dispatch(carteClientSearchAsync(data))
            .unwrap()
            .then(() => {
                handlecatalog(data)
                handlepackages(data)
            }).catch((error) => {
                console.log('error', error)
            })
        setReceiver(data.service_code)
    }


    const handlecatalog = async (val) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "agent_pin": "",
            "country": currentAgent.country,
            "service_code": val['service_code'],
            "token": currentAgent.access_token
        }
        dispatch(catalogSearchAsync(data))
    }

    const handlepackages = async (val) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "agent_pin": "",
            "country": currentAgent.country,
            "service_code": val.service_code,
            "token": currentAgent.access_token
        }
        dispatch(packagesSearchAsync(data))
    }

    const handleFees = async (pin) => {
        const data = {
            "country": currentAgent.country,
            "montant": montant,
            "operation": "COLLECTE",
            "pincode": pin,
            "receiver": receiver,
            "token": currentAgent.access_token,
        }
        dispatch(getFeesAsync(data))
    }

    const handleRecharging = async (pin) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "agent_pin": pin,
            "amount": montant,
            "country": currentAgent.country,
            "package_code": "",
            "service_code": receiver,
            "token": currentAgent.access_token
        }
        dispatch(rechargingAsync(data))
    }

    const getPin = (pin) => handleRecharging(pin)

    form.setFieldsValue({
        phonenumber: billStartimes.carteClient?.data?.mobile
    });
    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "startime"
        }
        dispatch(getTransactionAsync(data))
       
    }


    const resumeJson = {
        "title": "Paiement Startimes",
        "message": `Vous allez effectuer une transaction d'un montant de ${montant}F CFA vers le numéro ${receiver}`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (billStartimes.isSuccess) {
            dispatch(billStartimesClearState())
            form.resetFields()
        }
    }, [billStartimes, dispatch, form]);
    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billStartimesClearState())
        };
    }, [dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="Paiement Startime" />
            <Spin tip="Chargement..." spinning={billStartimes.carteClient.isFetching || frais.isFetching || billStartimes.recharging.isFetching}
            >
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={billStartimes.carteClient.status === "success" ? handleFees : billStartimes.recharging.status === "success" ? handleRecharging : handlesubscribers_info}>
                                <Form.Item
                                    label="Numéro de carte client"
                                    name="service_code"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        name="service_code"
                                        placeholder="Numéro de carte client"
                                        onChange
                                    />
                                </Form.Item>
                                {
                                    (billStartimes.carteClient.status === "success") && (
                                        <div>
                                            <Form.Item
                                                label="Téléphone du client:"
                                                name="phonenumber">
                                                <Input className="form-control" disabled />
                                            </Form.Item>
                                            <>
                                                <Form.Item label="Formule:">
                                                    <Select placeholder="Formule" style={{ width: "100%" }}>
                                                        {billStartimes.packages.data.map(element => <Option value={element.display_name}>{element.display_name}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label="Prix:">
                                                    <Select placeholder="Prix" style={{ width: "100%" }} onChange={handleChange}>
                                                        {billStartimes.catalogue.data.map(element => <Option value={element.amount}>{element.display_name}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </>
                                        </div>
                                    )}
                                <CustomBtn title={billStartimes.carteClient.status === "success" ? "Valider" : "Chercher"} />
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                    <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillStartime;

import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { showDialog } from '../../redux/dialog/dialog-slice';
import ReactCodesInput from "react-codes-input";
import "react-codes-input/lib/react-codes-input.min.css";


export function PinCodeDialog(props) {
    const dispatch = useDispatch();
    const [pin, setPin] = useState("");

    return (
        <Modal
            title={props.resume.title}
            visible={true}
            centered
            footer={null}
            closable={false}
            className="resume-transaction">
            {
                props.resume.title === "Retrait code" ?
                    <div style={{ padding: '0px 0px' }}>
                        <p style={{ padding: '0px 0px' }}>Veuillez renseigner votre code pin </p>
                        <div className="pincode">
                            <ReactCodesInput
                                initialFocus={true}
                                id="password"
                                codeLength={4}
                                type="alphanumeric"
                                hide={true}
                                onChange={(res) => setPin(res)} />
                        </div>

                        <Button
                            style={{ margin: '5px' }}
                            className="btn btn-primary"
                            onClick={() => dispatch(showDialog({ isPinCode: false }))}> Annuler </Button>
                        <Button
                            style={{ margin: '5px' }}
                            className="btn btn-secondary"
                            disabled={pin ? false : true}
                            onClick={() => { props.pinCode(pin); dispatch(showDialog({ isPinCode: false })) }}> Valider </Button>
                    </div> :
                    <div style={{ padding: '0px 0px' }}>
                        <p>{props.resume.message}</p>
                        <p className="fees">Frais: {parseInt(props.resume.fees)} FCFA <br /></p>
                        <p style={{ padding: '0px 0px' }}>Entrer votre code pin pour valider la transaction</p>
                        <div className="pincode">
                            <ReactCodesInput
                                initialFocus={true}
                                id="password"
                                codeLength={4}
                                type="alphanumeric"
                                hide={true}
                                onChange={(res) => setPin(res)} />
                        </div>

                        <Button
                            style={{ margin: '5px' }}
                            className="btn btn-primary"
                            onClick={() => dispatch(showDialog({ isPinCode: false }))}> Annuler </Button>
                        <Button
                            style={{ margin: '5px' }}
                            className="btn btn-secondary"
                            disabled={pin ? false : true}
                            onClick={() => { props.pinCode(pin); dispatch(showDialog({ isPinCode: false })) }}> Valider </Button>
                    </div>
            }
        </Modal>
    );
}
export default PinCodeDialog;

export const handleApiError = (error) => {
    console.log("error api:", error);
    if (error.code === "400") {
        return error.error ?? error.message;
    }
    if (error.code === 400) {
        return error.error ?? error.message;
    }

    if ((error.code === 403) || (error.code === "403")) {
        return error.error ?? error.message;
    }

    if (error.code === "500") {
        return error.error ?? error.message;
    }

    if (error.code === "WZ0011") {
        return error.error;
    }

    if (error.error !== undefined) {
        return error.error;
    }
    if (error.code === "WZ0100") {
        return error.error;
    } else {
        return "Impossible de traiter votre requête, erreur Interne du Serveur"
    }
}
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { UserOutlined, DollarOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../components/Button";
import { PinCodeDialog } from "../../components/Dialog/pincode-dialog";
import { agentSearchAsync, agentClearState } from "../../redux/agent-search/agent-search-slice";
import { getFeesAsync } from '../../redux/fees/fees-slice';
import CashoutDialog from "../../components/Dialog/cashout-dialog";
import { cashoutProvisionAsync, cashoutProvisionConfirmAsync, cashoutClearState } from "../../redux/cashout/cashout-provison-slice";
import { getTransactionAsync, transactionClearState } from "../../redux/transaction/transaction-slice";


export function CashoutProvisionPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [pincode, setPincode] = useState("");
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const agent = useSelector((state) => state.agent);
    const cashout = useSelector((state) => state.cashoutProvision);
    const [montant, setMontant] = useState("");
    const [receiver, setReceiver] = useState("");
    const [isTotalAgent, setIsTotalAgent] = useState(false);
    const transactions = useSelector((state) => state.transaction);

    const handleSearch = async (value) => {
        const data = {
            "phone_number": value.phone_number,
            "pincode": "",
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(agentSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.amount,
            "operation": "C_BON_AGENT",
            "pincode": "",
            "receiver": value.phone_number,
            "token": currentAgent.access_token
        }
        console.log(value)
        setReceiver(value.phone_number)
        setMontant(value.amount)
        dispatch(getFeesAsync(data))
    }

    const handleCashout = async (pin) => {
        const data = {
            "token": currentAgent.access_token,
            "phone_number": receiver,
            "pincode": pin,
            "montant": montant,
            "country": currentAgent.country
        }
        dispatch(cashoutProvisionAsync(data))
        setPincode(pin)
    }

    const handleCashoutConfirm = async (otp) => {
        const data = {
            "pincode": pincode,
            "otp": otp,
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(cashoutProvisionConfirmAsync(data))
    }
    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "provisions",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
    }

    const getPin = (pin) => handleCashout(pin)
    const getOtp = (otp) => handleCashoutConfirm(otp)


    form.setFieldsValue({
        firstname: agent?.data.first_name,
        lastname: agent?.data.last_name
    });


    const resumeJson = {
        "title": "Ordre de retrait",
        "message": `Vous êtes sur le point d´éffectuer une mise à disposition d´un montant de ${montant}F CFA vers le numéro ${currentAgent.phone}`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            responsive: ['lg'],
            render: (record) => <span>{record.amount} FCFA</span>
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(cashoutClearState())
        };
    }, [dispatch, form]);

    useEffect(() => {
        if (cashout.isSuccess) {
            dispatch(agentClearState())
            dispatch(cashoutClearState())
            form.resetFields()
        }
    }, [cashout, dispatch, form]);

    useEffect(() => {
        var total_agents = ['KYA_TH', 'KYA_TM', 'KYA_TL'];
        if (total_agents.includes(currentAgent.profile)) {
            setIsTotalAgent(true)
            form.setFieldsValue({ phone_number: "338649001" });
            const data = {
                "phone_number": "338649001",
                "pincode": "",
                "country": currentAgent.country,
                "token": currentAgent.access_token,
            }
            dispatch(agentSearchAsync(data))
        }
    }, [currentAgent, dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            {dialog.isCashout && <CashoutDialog title="ORDRE DE RETRAIT" otp={getOtp} />}
            <PageHeader title="ORDRE DE RETRAIT" />
            <Spin tip="Chargement..." spinning={agent.isFetching || frais.isFetching || cashout.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={agent.status === "success" ? handleFees : cashout.status === "success" ? handleCashoutConfirm : handleSearch}>
                                <Form.Item
                                    label="Numero de l'agent"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de telephone"
                                        disabled={isTotalAgent}
                                    />
                                </Form.Item>
                                {(agent.status === "success") && (
                                    <div>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Nom client"
                                                    name="firstname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Prénom client" name="lastname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            label="Montant"
                                            name="amount"
                                            rules={[{ pattern: new RegExp("^[0-9]+$"), message: "Montant incorrect" }, { required: true, message: 'Veuillez saisir un montant' }]}>
                                            <Input
                                                className="form-control"
                                                prefix={<DollarOutlined />}
                                                placeholder="Montant" />
                                        </Form.Item>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={agent.status === "success" ? "Valider" : cashout.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transactions.data}
                                locale={{
                                    emptyText: (transactions.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transactions.isFetching}
                                columns={columns} />

                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default CashoutProvisionPage;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_ROOT } from "../../utils/api-config";
import { handleApiError } from "../../utils/errorHandler";
import { showDialog } from "../dialog/dialog-slice";

export const billOnSearchAsync = createAsyncThunk(
  "billSaar/billOnSearchAsync",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${API_ROOT}api/saar/get/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${payload.token}`,
        },
        data: payload,
      });
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "TOKEN_EXPIRED" ||
        error.response.data.message === "Unauthorized"
      )
        return rejectWithValue(dispatch(showDialog({ isLockscreen: true })));
      else
        return rejectWithValue(
          dispatch(
            showDialog({
              isError: true,
              errorMessage: handleApiError(error.response.data),
            })
          )
        );
    }
  }
);

export const billSaarPayAsync = createAsyncThunk(
  "billSaar/billSaarPayAsync",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${API_ROOT}api/saar/pay/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${payload.token}`,
          "App-Platform": "1",
        },
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.log("#", error.response);
    }
  }
);

export const billSaarSlice = createSlice({
  name: "billSaar",
  initialState: {
    status: "idle",
    isFetching: false,
    isSuccess: false,
    customerName: "",
    referenceInfo: "",
  },
  reducers: {
    billSaarClearState: (state) => {
      state.status = "idle";
      state.isSuccess = false;
      state.isFetching = false;
      state.customerName = "";
      state.referenceInfo = "";
      return state;
    },
  },
  extraReducers: {
    [billOnSearchAsync.pending](state) {
      state.status = "pending";
      state.isFetching = true;
    },
    [billOnSearchAsync.fulfilled](state, { payload }) {
      state.status = "success";
      state.isFetching = false;
      state.referenceInfo = payload;
    },
    [billOnSearchAsync.rejected](state) {
      state.status = "failed";
      state.isFetching = false;
    },
    [billSaarPayAsync.pending](state) {
      state.status = "pending";
      state.isFetching = true;
    },
    [billSaarPayAsync.fulfilled](state) {
      state.status = "success";
      state.isFetching = false;
      state.isSuccess = true;
    },
    [billSaarPayAsync.rejected](state) {
      state.status = "failed";
      state.isFetching = false;
    },
  },
});

export const { billSaarClearState } = billSaarSlice.actions;
export default billSaarSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
// import baseUrl from '../../utils/api';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const billDiobassSearchAsync = createAsyncThunk(
    'billSeneau/billSeneauSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/diobass/get/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            })
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Wrong credentials")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const billDiobassPayAsync = createAsyncThunk(
    'billDiobass/billDiobassPayAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/diobass/reload/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Wrong credentials")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const billDiobassSlice = createSlice({
    name: 'billDiobass',
    initialState: {
        status: 'idle',
        isFetching: false,
        data: "",
        factures: []
    },
    reducers: {
        billDiobassClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;

        },
    },
    extraReducers: {
        [billDiobassSearchAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billDiobassSearchAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.data = payload
            state.factures = payload.factures
        },
        [billDiobassSearchAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [billDiobassPayAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billDiobassPayAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [billDiobassPayAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        }
    }
});

export const { billDiobassClearState } = billDiobassSlice.actions;
export default billDiobassSlice.reducer;

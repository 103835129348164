import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button } from 'antd';
import { getBalanceAsync, getCommissionAsync, getTransactionCountAsync } from '../../redux/journalSlice';


export function HomePage() {
    const dispatch = useDispatch();
    const { balance, commission, transaction, isFetchingBalance, isFetchingCommision, isFetchingTransaction } = useSelector((state) => state.journal);
    const { currentAgent } = useSelector((state) => state.auth);
    const [isBalance, setIsBalance] = useState(false);
    const [isCommission, setIsCommission] = useState(false);
    const [isTransaction, setIsTransaction] = useState(false);

    const handleSolde = useCallback(() => {
        const data = {
            "country": currentAgent?.country,
            "pincode": "",
            "token": currentAgent?.access_token
        }
        dispatch(getBalanceAsync(data));
    }, [dispatch, currentAgent])

    const handleCommission = useCallback(() => {
        const data = {
            "country": currentAgent?.country,
            "pincode": "",
            "token": currentAgent?.access_token
        }
        dispatch(getCommissionAsync(data));
    }, [dispatch, currentAgent])

    const handleTransaction = useCallback(() => {
        const data = {
            "country": currentAgent?.country,
            "pincode": "",
            "token": currentAgent?.access_token
        }
        dispatch(getTransactionCountAsync(data));
    }, [dispatch, currentAgent])

    
    return (
        <div className="home-page">
            <Row gutter={24}>
                <Col lg={8} xs={24}>
                    <Card>
                        <h4>SOLDE MONNAIE ELECTRONIQUE</h4>
                        {isBalance ?
                            <Button className="btn-secondary" onClick={() => { handleSolde(); setIsBalance(true) }}>{isFetchingBalance ? "Chargement..." : `Solde: ${balance} F CFA`} </Button> :
                            <Button className="btn-secondary" onClick={() => { handleSolde(); setIsBalance(true) }}>{isFetchingBalance ? "Chargement..." : "Voir mon solde"} </Button>
                        }
                    </Card>
                </Col>
                <Col lg={8} xs={24}>
                    <Card>
                        <h4>COMMISSIONS GAGNÉES</h4>
                        {isCommission ?
                            <Button className="btn-secondary" onClick={() => { handleCommission(); setIsCommission(true) }}>{isFetchingCommision ? "Chargement..." : `Commissions: ${commission} F CFA`} </Button> :
                            <Button className="btn-secondary" onClick={() => { handleCommission(); setIsCommission(true) }}>{isFetchingCommision ? "Chargement..." : "Voir mes commissions"} </Button>
                        }
                    </Card>
                </Col>
                <Col lg={8} xs={24}>
                    <Card>
                        <h4>NOMBRE DE TRANSACTIONS</h4>
                        {isTransaction ?
                            <Button className="btn-secondary" onClick={() => { handleTransaction(); setIsTransaction(true) }}>{isFetchingTransaction ? "Chargement..." : `Transactions: ${transaction}`} </Button> :
                            <Button className="btn-secondary" onClick={() => { handleTransaction(); setIsTransaction(true) }}>{isFetchingTransaction ? "Chargement..." : "Voir mes transactions"} </Button>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
export default HomePage;

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Input, PageHeader, Table, DatePicker, Select, Button, Tag } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../components/Button";
import { getTransactionAsync, transactionClearState } from "../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../utils/api-config";
import moment from 'moment'
import { CSVLink } from "react-csv";
import { transactionType, matchingTypes, matchingCoutry, country, getNumber, getDetails, factureType } from "../../utils/globalVars";


export function Transaction() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const transactions = useSelector((state) => state.transaction);
    const { Option } = Select;
    const [typeTransaction, setTypeTransaction] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [idTransaction, setIdTransaction] = useState("")


    function handleChange(value) {
        setTypeTransaction(value)
    }

    function handleStartDate(value) {
        var dateStringWithStartTime = moment(value).format('DD/MM/YYYY');
        setStartDate(dateStringWithStartTime)
    }

    function handleEndDate(value) {
        var dateStringWithEndTime = moment(value).format('DD/MM/YYYY');
        setEndDate(dateStringWithEndTime)
    }

    const onChange = e => {
        setIdTransaction(e.target.value);
    };

    const handleTransaction = async () => {
        const data = {
            "country": currentAgent.country,
            "ed": endDate,
            "pincode": "",
            "sd": startDate,
            "token": currentAgent.access_token,
        }
        dispatch(getTransactionAsync(data))

    }

    const handleSearchIDTransaction = async () => {
        const data = {
            "country": currentAgent.country,
            "ed": "",
            "pincode": "",
            "sd": "",
            "token": currentAgent.access_token,
            "trans_id": idTransaction
        }
        dispatch(getTransactionAsync(data))
    }


    const handleSearchTypeTransaction = async () => {
        const data = {
            "country": currentAgent.country,
            "ed": "",
            "pincode": "",
            "sd": "",
            "token": currentAgent.access_token,
            "type": typeTransaction
        }
        dispatch(getTransactionAsync(data))
    }

    const handleSearchTypeDateTransaction = async () => {
        const data = {
            "country": currentAgent.country,
            "ed": endDate,
            "pincode": "",
            "sd": startDate,
            "token": currentAgent.access_token,
            "type": typeTransaction
        }
        dispatch(getTransactionAsync(data))
    }


    const handleSearchDefaultTransaction = async () => {
        const data = {
            "country": currentAgent.country,
            "ed": "",
            "pincode": "",
            "sd": "",
            "token": currentAgent.access_token,
        }
        dispatch(getTransactionAsync(data))
    }
    const getMatchingTypes = (
        type,
        externalComment = '',
        externatInfo = '',
        receiverBank = '',
    ) => {
        if (type in matchingTypes(externalComment, externatInfo, receiverBank)) {
            return matchingTypes(externalComment, externatInfo, receiverBank)[type];
        }
        return type;
    };

    const getMatchingCountry = pays => {
        if (pays in matchingCoutry()) {
            return matchingCoutry()[pays];
        }
        return pays;
    };
    const currency = (number) => {
        return new Intl.NumberFormat('fr-FR', {
            currency: 'XOF',
            useGrouping: true,
        }).format(number)
    }
    const currentUserCountry = country();
    const getFlag = (type, country_sender, country_receiver) => {
        let flag = '';
        const trxTypes = [
            'transfer_register',
            'transfer_no_register',
            'cashout_international',
        ];

        if (trxTypes.includes(type)) {
            flag =
                currentUserCountry === country_sender
                    ? country_receiver
                    : currentUserCountry;
        } else {
            flag = currentUserCountry;
        }
        return flag;
    };

    useEffect((value) => {
        const data = {
            "country": currentAgent.country,
            "ed": moment(value).format('DD/MM/YYYY'),
            "pincode": "",
            "sd": moment(value).format('DD/MM/YYYY'),
            "token": currentAgent.access_token,
        }
        dispatch(getTransactionAsync(data))
    }, [dispatch, currentAgent]);


    function handleSearch() {
        if (idTransaction.length > 0 && typeTransaction.length === 0 && startDate.length === 0 && endDate.length === 0) { handleSearchIDTransaction() }
        else if (typeTransaction.length > 0 && idTransaction.length === 0 && startDate.length === 0 && endDate.length === 0) { handleSearchTypeTransaction() }
        else if (startDate.length > 0 && endDate.length > 0 && idTransaction.length === 0 && typeTransaction.length === 0) { handleTransaction() }
        else if (startDate.length > 0 && endDate.length > 0 && typeTransaction.length > 0 && idTransaction.length === 0) { handleSearchTypeDateTransaction() }
        else if (idTransaction.length === 0 && typeTransaction === 0 && startDate.length === 0 && endDate.length === 0) { handleTransaction() }
        else
            handleSearchDefaultTransaction()
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Type',
            render: (record) => <Tag color="blue">{transactionType(record.type, record.external_comment, record.receiver)}</Tag>,
            responsive: ['lg'],

        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Frais',
            render: (record) => <span>{parseInt(record.frais)} FCFA</span>,
            responsive: ['lg'],
        },
        {
            title: 'Commissions',
            render: (record) => <span>{record.commissions} FCFA</span>,
            responsive: ['lg'],
        },
        {
            title: 'Numero / Police client',
            dataIndex: 'receiver',
            render: (text, row) =>
                getMatchingTypes(row.type) === 'AJUSTEMENT' ||
                    getMatchingTypes(row.type) === `ANNULATION D'AJUSTEMENT`
                    ? `Wizall ${getMatchingCountry(currentAgent.country)}`
                    : row[getDetails(row.type.toLowerCase(), row.external_comment)],
            responsive: ['lg'],
        },
        // {
        //     title: 'Pays',
        //     dataIndex: 'country'
        // },
        {
            title: 'Numero Agent',
            dataIndex: 'sender',
            render: (text, row) =>
                row[getNumber(row.type.toLowerCase(), row.external_comment)],
        },
        {
            title: 'Commentaires',
            dataIndex: 'external_info',
            responsive: ['lg'],
        },

        {
            title: 'Imprimer',
            render: (record) => <span>
                <a href={API_ROOT + "api/imprime/" + factureType(record.type) + "/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
        };
    }, [dispatch, form]);

    const onReset = () => {
        form.resetFields();
    };

    const handleClear = () => {
        dispatch(transactionClearState())
        onReset()
    }

    const getDateTime = () => {
        const now = new Date();
        const year = `${now.getFullYear()}`;
        let month = `${now.getMonth() + 1}`;
        if (month.length === 1) {
            month = `0${month}`;
        }
        let day = `${now.getDate()}`;
        if (day.length === 1) {
            day = `0${day}`;
        }
        let hour = `${now.getHours()}`;
        if (hour.length === 1) {
            hour = `0${hour}`;
        }
        let minute = `${now.getMinutes()}`;
        if (minute.length === 1) {
            minute = `0${minute}`;
        }
        let second = `${now.getSeconds()}`;
        if (second.length === 1) {
            second = `0${second}`;
        }
        return `${year}-${month}-${day}-${hour} ${minute} ${second}`;
    }

    const detailsItems = [
        { label: 'ID Transaction', key: 'id' },
        { label: 'Date', key: 'datetransformatted' },
        {
            label: 'Type de transfert',
            key: 'type',
            
        },
        {
            label: 'Montant',
            key: 'amount',
          
        },
        { label: 'Frais', key: 'frais' },
        {
            label: 'Commission',
            key: 'commissions'

        },
        {
            label: 'Numero / Police client',
            key: 'receiver'
        },
        {
            label: 'Pays',
            key: 'country_receiver'

        },
        {
            label: 'Numero Agent',
            key: 'sender'
        },
        { label: 'Commentaires', key: 'external_info' },
    ];


    return (
        <div className="default-page">
            <PageHeader title="Historique des Transactions" />
            <Card>
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={handleSearch}>
                    <br />
                    <Row gutter={[16, 16]}>
                        <Col lg={5} xs={24}>
                            <Form.Item label="Début Date" name="Start_Date" style={{ width: "100%" }}>
                                <DatePicker className="form-control" placeholder={moment(Date.now()).format('DD/MM/YYYY')} style={{ width: "100%" }} onChange={handleStartDate} />
                            </Form.Item>
                        </Col>

                        <Col lg={5} xs={24}>
                            <Form.Item label="Fin Date" name="End_Date" style={{ width: "100%" }}>
                                <DatePicker className="form-control" placeholder={moment(Date.now()).format('DD/MM/YYYY')} style={{ width: "100%" }} onChange={handleEndDate} />
                            </Form.Item>
                        </Col>

                        <Col lg={5} xs={24}>
                            <Form.Item label="Numero de transaction" name="numero_transaction" style={{ width: "100%" }}>
                                <Input className="form-control" style={{ width: "100%" }} placeholder="Numero de transaction" onChange={onChange} />
                            </Form.Item>
                        </Col>

                        <Col lg={5} xs={24}>
                            <Form.Item label="Type de transaction" style={{ width: "100%" }}>
                                <Select name="select" placeholder="Toutes" style={{ width: "100%", height: 40 }} onChange={handleChange} allowClear>
                                    <Option value="cashin">Depots</Option>
                                    <Option value="cashout">Retraits</Option>
                                    <Option value="collecte">Collectes</Option>
                                    <Option value="transferts">Transfert</Option>
                                    <Option value="factures">Factures</Option>
                                    <Option value="operateurs">Operateurs</Option>
                                    <Option value="recharges">Recharges</Option>
                                    <Option value="provisions">Provisions</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={4} xs={24}>
                            <Form.Item>
                                <div style={{ display: 'flex', marginTop: '30px' }}>
                                    <CustomBtn title={transactions.status === "success" ? "Valider" : "Chercher"} />
                                    <Button className="btn btn-lg btn-primary" onClick={() => handleClear()} style={{ marginLeft: '4px' }} >Effacer</Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <Table
                        size="small"
                        dataSource={transactions.data}
                        loading={transactions.isFetching}
                        columns={columns} />
                    <center>
                        {transactions.data && <CSVLink
                            data={
                                transactions.data.map(x => ({
                                    country_receiver: getMatchingCountry(
                                        getFlag(
                                            x.type,
                                            x.country_sender,
                                            x.country_receiver,
                                        ),
                                    ),
                                    id: x.id,
                                    datetransformatted: x.datetransformatted,
                                    type: getMatchingTypes(
                                        x.type.toLowerCase(),
                                        x.external_comment,
                                        x.receiver,
                                    ),
                                    amount: currency(x.amount),
                                    frais: currency(x.frais),
                                    commissions: currency(x.commissions),
                                    // sender: x.sender,
                                    sender:
                                        x[
                                        getNumber(
                                            x.type.toLowerCase(),
                                            x.external_comment,
                                        )
                                        ],
                                    receiver:
                                        x[
                                        getDetails(
                                            x.type.toLowerCase(),
                                            x.external_comment,
                                        )
                                        ],
                                    external_info: x.external_info,
                                })) || []
                            }
                            headers={detailsItems}
                            asyncOnClick
                            // column={columns}
                            separator=";"
                            filename={`Transaction-report-${getDateTime()}.csv`}
                            target="_blank"
                        >
                            <br /><br />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button className="btn btn btn-primary">
                                    Telecharger
                                </Button>
                            </div>
                        </CSVLink>}
                    </center>
                </Form>
            </Card>
        </div>
    );
}
export default Transaction;
import React, { useState, useEffect } from "react";
import { Row, Col, Card, PageHeader, Button, Alert, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactCodesInput from "react-codes-input";
import "react-codes-input/lib/react-codes-input.min.css";
import { clearState, updateAsync } from '../../redux/auth/auth-slice';

export function PinCodeResetPage() {
    const dispatch = useDispatch();
    const { currentAgent, status } = useSelector((state) => state.auth);
    const [currentPinCode, setCurrentPinCode] = useState("");
    const [newPinCode, setNewPinCode] = useState("");
    const [newPinCodeConfirm, setNewPinCodeConfirm] = useState("");


    const handleChangeCurrentPinCode = (value) => {
        setCurrentPinCode(value);
    };

    const handleChangeNewPinCode = (value) => {
        setNewPinCode(value);
    };

    const handleChangeNewPinCodeConfirm = (value) => {
        setNewPinCodeConfirm(value);
    };

    const handleSubmit = async () => {
        if (newPinCode !== newPinCodeConfirm) {
            alert("Les deux mots de passe ne sont pas conformes");
        } else {
            const data = {
                "new_pin": newPinCode,
                "pincode": currentPinCode,
                "country": currentAgent.country,
                "token": currentAgent.access_token,
            }
            dispatch(updateAsync(data));
        }
    }


    useEffect(() => {
        return () => {
            dispatch(clearState())
        }
    }, [dispatch]);


    return (
        <div className="default-page">
            <PageHeader title="Mise a jour du code pin" />
            <Spin tip="Chargement..." spinning={status === "pending" ? true : false}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <p style={{ marginTop: '15px' }}>ENTREZ VOTRE CODE PIN ACTUEL/CODE D'ACTIVATION </p>
                            <div className="pincode">
                                <ReactCodesInput
                                    initialFocus={false}
                                    id="currentPinCode"
                                    name="currentPinCode"
                                    type="pinCode"
                                    hide={true}
                                    codeLength={4}
                                    onChange={handleChangeCurrentPinCode}
                                    value={currentPinCode}
                                />
                            </div>
                            <br />
                            <>
                                <p style={{ marginTop: '5px' }}>ENTREZ LE NOUVEAU CODE PIN </p>
                                <div className="pincode1">
                                    <ReactCodesInput
                                        initialFocus={false}
                                        id="newPinCode"
                                        name="newPinCode"
                                        type="pinCode"
                                        hide={true}
                                        codeLength={4}
                                        onChange={handleChangeNewPinCode}
                                        value={newPinCode}
                                    />
                                </div>
                            </>
                            <br />
                            <>
                                <p style={{ marginTop: '5px' }}>CONFIRMER VOTRE NOUVEAU CODE PIN </p>
                                <div className="pincode1">
                                    <ReactCodesInput
                                        initialFocus={false}
                                        id="newPinCodeConfirm"
                                        name="newPinCodeConfirm"
                                        type="pinCode"
                                        hide={true}
                                        codeLength={4}
                                        onChange={handleChangeNewPinCodeConfirm}
                                        value={newPinCodeConfirm}
                                    />
                                </div>
                            </>
                            <br />
                            <Alert
                                message=""
                                description="Le nouveau Code PIN ne doit pas être une suite de chiffres consécutifs (ex: 1234), identique (ex: 0000) ou égale à la date de naissance."
                                type="warning"
                                showIcon
                                closable />
                            <Button
                                style={{ marginTop: '25px' }}
                                className="btn btn-secondary"
                                onClick={handleSubmit}> Valider </Button>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>

    );
}
export default PinCodeResetPage;
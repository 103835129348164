import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Radio, Button } from 'antd';
import { UserOutlined, DollarOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { PrinterOutlined } from '@ant-design/icons';
import { CustomBtn } from "../../components/Button";
import { PinCodeDialog } from "../../components/Dialog/pincode-dialog";
import { codeSearchAsync, codeClearState } from "../../redux/code-search/code-search-slice";
import { cashoutCodeAsync, cashoutCodeClearState } from "../../redux/cashout/cashout-code-sclice";
import { showDialog } from "../../redux/dialog/dialog-slice";
import { getTransactionAsync, transactionClearState } from "../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../utils/api-config";

const initialCodeState = {
    code1: "",
    code2: "",
    code3: ""
};

export function CashoutCodePage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const code = useSelector((state) => state.code);
    const transaction = useSelector((state) => state.transaction);
    const cashout = useSelector((state) => state.cashout);
    const [cashoutCode, setCashoutCode] = useState(initialCodeState);
    const [transferCode, setTransferCode] = useState("");
    const [kycType, setKycType] = useState(1);
    const [kyc_number, setKyc_number] = useState("");

    const onChange = (e) => setKycType(e.target.value);

    const handleCashoutCode = () => {
        const data = (cashoutCode.code1).toUpperCase() + cashoutCode.code2 + cashoutCode.code3.toUpperCase();
        if (data) {
            dispatch(showDialog({ isCashoutCode: true }));
            setTransferCode(data);
        }
    }

    const handleSearch = async (pin, value) => {
        const data = {
            "country": currentAgent.country,
            "pincode": pin,
            "token": currentAgent.access_token,
            "transfer_code": transferCode,
            "agent_msdn": currentAgent.phone
        }
        dispatch(codeSearchAsync(data))
    }

    const getMontant = parseInt((code?.data.value))

    const showPinCodeDialog = async (value) => {
        const data = (cashoutCode.code1).toUpperCase() + cashoutCode.code2 + cashoutCode.code3.toUpperCase();
        dispatch(showDialog({ isPinCode: true }))
        setKyc_number(value.kyc_number)
        setTransferCode(data);
    }


    const handleCashout = async () => {
        const data = {
            "amount": getMontant,
            "country": currentAgent.country,
            "kyc_number": kyc_number,
            "kyc_type": kycType,
            "pincode": "",
            "secure_code": "",
            "token": currentAgent.access_token,
            "transfer_code": transferCode
        }
        dispatch(cashoutCodeAsync(data))
            .unwrap()
            .then(() => {
                setCashoutCode(initialCodeState);
            }).catch((error) => {
                console.log('error', error)
            })
    }

    const getPin = (pin) => handleSearch(pin)
    const getPinCode = (pin) => handleCashout(pin)

    form.setFieldsValue({
        firstname: code.data.recipient?.first_name,
        montant: parseInt(code.data.value),
    });

    const resumeJson = {
        "title": "Resumé de la transaction",
        "message": `Vous êtes sur le point d´éffectuer un retrait d´un montant de ${getMontant}F CFA vers le numéro ${currentAgent.phone}`,
        "fees": 0
    }
    const verificationJson = {
        "title": "Retrait code",
        "message": `Vous etes sur le point d'éffectuer une vérification de bon. Tapez votre code PIN puis valider`,
        "fees": null
    }

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "BON_CASH"
        }
        dispatch(getTransactionAsync(data))
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'sender',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/transfert/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }

    ];

    useEffect(() => {
        if (cashout.isSuccess) {
            dispatch(codeClearState())
            dispatch(cashoutCodeClearState())
            form.resetFields()
        }
    }, [cashout, dispatch, form]);

    const letterControl = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^A-Za-z]/, "");
        return currentValue;
    };

    const digitControl = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^0-9]/, "");
        return currentValue;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const value2 = (name === "code1" || name === "code3") ? letterControl(value) : digitControl(value);
        setCashoutCode({ ...cashoutCode, [name]: value2 });
    };


    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(codeClearState())
            dispatch(cashoutCodeClearState())
        };
    }, [dispatch]);

    return (
        <div className="default-page">
            {dialog.isCashoutCode && <PinCodeDialog resume={verificationJson} pinCode={getPin} />}
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPinCode} />}
            <PageHeader title="Effectuer un retrait" />
            <Spin tip="Chargement..." spinning={code.isFetching || cashout.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="complex-form"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={code.status === "success" ? showPinCodeDialog : handleCashoutCode}>

                                <Form.Item label="Code Retrait">
                                    <Input.Group compact>
                                        <input
                                            type="text"
                                            name="code1"
                                            maxlength="4"
                                            value={cashoutCode.code1}
                                            onChange={handleInputChange}
                                            style={{ width: '33%', textTransform: "uppercase" }}
                                            className="ant-input form-control"
                                        />
                                        <input
                                            type="text"
                                            name="code2"
                                            maxlength="4"
                                            value={cashoutCode.code2}
                                            onChange={handleInputChange}
                                            className="ant-input form-control"
                                            style={{ width: '33%' }}
                                        />
                                        <input
                                            type="text"
                                            name="code3"
                                            maxlength="4"
                                            value={cashoutCode.code3}
                                            onChange={handleInputChange}
                                            style={{ width: '33%', textTransform: "uppercase" }}
                                            className="ant-input form-control"
                                        />
                                    </Input.Group>
                                </Form.Item>
                                {(code.status === "success") && (
                                    <div>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Nom client"
                                                    name="firstname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                Type de pièce:
                                                <Radio.Group onChange={onChange} value={kycType}>
                                                    <Radio name="kyc_type" value={1}>Carte d'identité</Radio>
                                                    <Radio name="kyc_type" value={2}>Passport</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={kycType === 1 ? "Numéro de pièce d'identité" : "Numéro du Passeport"}
                                                    name="kyc_number"
                                                    rules={[{ required: true, message: 'Champs requis' }]}>

                                                    <Input
                                                        className="form-control"
                                                        prefix={<DollarOutlined />}
                                                        placeholder={kycType === 1 ? "Numéro de pièce d'identité" : "Numéro du Passeport"} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Montant"
                                                    name="montant">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={code.status === "success" ? "Valider" : cashout.status === "success" ? "Confirmer" : "Valider"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default CashoutCodePage;

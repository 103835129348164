import { createSlice } from '@reduxjs/toolkit';

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState: {
        data: '',
        isLockscreen: false,
        isError: false,
        isSuccess: false,
        isCashout: false,
        isPinCode: false,
        isCashoutCode: false,
        isFetching: false,
        isPinSetted: true,
        successMessage: '',
        errorMessage: '',
        type: ''
    },
    reducers: {
        showDialog: (state, { payload }) => {
            state.isError = payload.isError
            state.isSuccess = payload.isSuccess
            state.isPinCode = payload.isPinCode
            state.isCashout = payload.isCashout
            state.isCashoutCode = payload.isCashoutCode
            state.isLockscreen = payload.isLockscreen
            state.isPinSetted = payload.isPinSetted
            state.errorMessage = payload.errorMessage
            state.successMessage = payload.successMessage
            state.type = payload.type
        }
    },
});

export const { showDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
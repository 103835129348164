import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Spin,
  PageHeader,
  Table,
  Button,
} from "antd";
import { DollarOutlined } from "@ant-design/icons";
import { PrinterOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from "../../../redux/fees/fees-slice";
import {
  billSaarClearState,
  billOnSearchAsync,
  billSaarPayAsync,
} from "../../../redux/bills/bill-saar-slice";
import {
  getTransactionAsync,
  transactionClearState,
} from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";
import { showDialog } from "../../../redux/dialog/dialog-slice";

export function BillSaarPage() {
  const dispatch = useDispatch();
  const { currentAgent } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const dialog = useSelector((state) => state.dialog);
  const frais = useSelector((state) => state.fees);
  const saar = useSelector((state) => state.billSaar);
  const [amount, setMontant] = useState("");
  const [receiver, setReceiver] = useState("");
  const transaction = useSelector((state) => state.transaction);
  const [compteur, setCompteur] = useState("");

  const handleSearch = async (value) => {
    const data = {
      phone_number: value.phone_number,
      pincode: "",
      number_receipt: value.compteur_number,
      country: currentAgent.country,
      token: currentAgent.access_token,
    };
    dispatch(billOnSearchAsync(data));
  };

  const handleFees = async (value) => {
    const data = {
      country: currentAgent.country,
      montant: value.amount,
      operation: "COLLECTE",
      receiver: "22220",
      pincode: "",
      token: currentAgent.access_token,
    };
    setReceiver(value.phone_number);
    setMontant(value.amount);
    setCompteur(value.compteur_number);
    dispatch(getFeesAsync(data));
  };

  const handleReload = async (pin) => {
    const data = {
      token: currentAgent.access_token,
      phone_number: receiver,
      receveurmsisdn: receiver,
      entreprise_code: "22220",
      comment: receiver,
      pincode: pin,
      number_receipt: compteur,
      session_id: saar.sessionId,
      amount: amount,
      country: currentAgent.country,
    };
    dispatch(billSaarPayAsync(data))
      .unwrap()
      .then((res) => {
        dispatch(
          showDialog({
            isSuccess: true,
            successMessage: "Paiement effectué avec succès",
          })
        );
      })
      .catch(() => {});
  };

  const getPin = (pin) => handleReload(pin);
  const handleTransaction = async () => {
    const data = {
      count: 10,
      country: currentAgent.country,
      pincode: "",
      token: currentAgent.access_token,
      type: "payment_saar",
    };
    dispatch(getTransactionAsync(data));
  };

  form.setFieldsValue({ duration: saar.referenceInfo.duration });

  const resumeJson = {
    title: "Paiement Saar",
    message: `Vous allez effectuer un paiement d'un amount de ${amount}F CFA vers le numéro ${receiver}`,
    fees: frais.fees,
  };

  const columns = [
    {
      title: "ID transactions",
      dataIndex: "id",
    },
    {
      title: "Dates",
      dataIndex: "datetransformatted",
    },
    {
      title: "Téléphones",
      responsive: ["lg"],
      dataIndex: "receiver",
    },
    {
      title: "Montants",
      render: (record) => <span>{record.amount} FCFA</span>,
    },
    {
      title: "Imprimer",
      responsive: ["lg"],
      render: (record) => (
        <span>
          <a
            href={
              API_ROOT +
              "api/imprime/factures/" +
              record.id +
              "/?token=" +
              currentAgent.access_token +
              "&country=" +
              currentAgent.country +
              ""
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            <PrinterOutlined style={{ fontSize: "20px" }} />{" "}
          </a>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (saar.isSuccess) {
      dispatch(billSaarClearState());
      form.resetFields();
    }
  }, [saar, dispatch, form]);

  useEffect(() => {
    return () => {
      dispatch(transactionClearState());
    };
  }, [dispatch]);

  return (
    <div className="default-page">
      {dialog.isPinCode && (
        <PinCodeDialog resume={resumeJson} pinCode={getPin} />
      )}
      <PageHeader title="PAIEMENT SAAR" />
      <Spin tip="Chargement..." spinning={saar.isFetching || frais.isFetching}>
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Card>
              <Form
                name="basic"
                form={form}
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={
                  saar.status === "success"
                    ? handleFees
                    : saar.status === "success"
                    ? handleReload
                    : handleSearch
                }
              >
                <Form.Item
                  label="Numero du client"
                  name="phone_number"
                  rules={[
                    { required: true, message: "Veuillez saisir un numéro" },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Numero du client"
                  />
                </Form.Item>
                <Form.Item
                  label="Numéro de quittance"
                  name="compteur_number"
                  rules={[
                    { required: true, message: "Veuillez saisir un numéro" },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Numéro de quittance"
                  />
                </Form.Item>
                {saar.status === "success" && (
                  <div>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item label="Durée" name="duration">
                          <Input className="form-control" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Montant"
                      name="amount"
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]+$"),
                          message: "Montant incorrect",
                        },
                        {
                          required: true,
                          message: "Veuillez saisir un amount",
                        },
                      ]}
                    >
                      <Input
                        className="form-control"
                        prefix={<DollarOutlined />}
                        placeholder="Montant"
                      />
                    </Form.Item>
                  </div>
                )}
                <Form.Item>
                  <CustomBtn
                    title={
                      saar.status === "success"
                        ? "Valider"
                        : saar.status === "success"
                        ? "Confirmer"
                        : "Chercher"
                    }
                  />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col lg={12} xs={24}>
            <Card>
              <Table
                dataSource={transaction.data}
                locale={{
                  emptyText: transaction.data ? null : (
                    <Button
                      onClick={() => handleTransaction()}
                      size="large"
                      className="btn btn-secondary"
                    >
                      {"Afficher l'historique des transactions"}
                    </Button>
                  ),
                }}
                loading={transaction.isFetching}
                columns={columns}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
export default BillSaarPage;

import { configureStore } from "@reduxjs/toolkit";
import journalSlice from "./journalSlice";
import { loadAgentState } from "./loadStorage";
import authSlice from "./auth/auth-slice";
import feesSlice from "./fees/fees-slice";
import dialogSlice from "./dialog/dialog-slice";
import agentSearchSlice from "./agent-search/agent-search-slice";
import cardSearchSlice from "./card-search/card-search-slice";
import cashoutProvisionSlice from "./cashout/cashout-provison-slice";
import cashoutCardSlice from "./cashout/cashout-card-slice";
import cashoutCostumerSlice from "./cashout/cashout-costumer-slice";
import collectSlice from "./collect/collect-slice";
import enterpriseSearchSlice from "./enterprise-search/enterprise-search-slice";
import cashoutCodeSlice from "./cashout/cashout-code-sclice";
import cashinSlice from "./cashin/cashin-slice";
import costumerSearchSlice from "./costumer-search/costumer-search-slice";
import codeSearchSlice from "./code-search/code-search-slice";
import billSeneauSlice from "./bills/bill-seneau-slice";
import billSenelecSlice from "./bills/bill-senelec-slice";
import billBboxxSlice from "./bills/bill-bboxx-slice";
import billStartimesSlice from "./bills/bill-startimes-slice";
import billRapidoSlice from "./bills/bill-rapido-slice";
import billToubaCaKanamSlice from "./bills/bill-touba-ca-kanam-slice";
import billDstvSlice from "./bills/bill-dstv-slice";
import airtimeSlice from "./airtime/airtime-slice";
import billWoyofalSlice from "./bills/bill-woyofal-slice";
import billOoluSlice from "./bills/bill-oolu-slice";
import billAnsambleSlice from "./bills/bill-ansamble-slice";
import featureSlice from "./features/feature-slice";
import transactionSlice from "./transaction/transaction-slice";
import billMintrouteSlice from "./bills/bill-mintroute-slice";
import billSomagepSlice from "./bills/bill-somagep-slice";
import billCanalSlice from "./bills/bill-canal-slice";
import billIsagoSlice from "./bills/bill-isago-slice";
import billDiobassSlice from "./bills/bill-diobass-slice";
import billCampusenSlice from "./bills/bill-campusen-slice";
import billSaarSlice from "./bills/bill-saar-slice";
import billSodeciSlice from "./bills/bill-sodeci-slice";
import mapSlice from "./maps/map-slice";
import billDerSlice from "./bills/bill-der-slice";
import transfertSlice from "../pages/transfert/slice/transfert-slice";

const persistance = loadAgentState();

export default configureStore({
  reducer: {
    auth: authSlice,
    airtime: airtimeSlice,
    billDstv: billDstvSlice,
    billDiobass: billDiobassSlice,
    cashoutCostumer: cashoutCostumerSlice,
    cashoutProvision: cashoutProvisionSlice,
    cashoutCard: cashoutCardSlice,
    cashin: cashinSlice,
    cashout: cashoutCodeSlice,
    costumer: costumerSearchSlice,
    agent: agentSearchSlice,
    card: cardSearchSlice,
    enterprise: enterpriseSearchSlice,
    collecte: collectSlice,
    dialog: dialogSlice,
    fees: feesSlice,
    code: codeSearchSlice,
    journal: journalSlice,
    billSeneau: billSeneauSlice,
    billDer: billDerSlice,
    billSenelec: billSenelecSlice,
    billSaar: billSaarSlice,
    billBboxx: billBboxxSlice,
    billStartimes: billStartimesSlice,
    billRapido: billRapidoSlice,
    billWoyofal: billWoyofalSlice,
    billToubaCaKanam: billToubaCaKanamSlice,
    billOolu: billOoluSlice,
    billIsago: billIsagoSlice,
    billAnsamble: billAnsambleSlice,
    billSomagep: billSomagepSlice,
    billCanal: billCanalSlice,
    billCampusen: billCampusenSlice,
    feature: featureSlice,
    billSodeci: billSodeciSlice,
    transfert: transfertSlice,
    minteroute: billMintrouteSlice,
    map: mapSlice,
    persistance: persistance,
    transaction: transactionSlice,
  },
});

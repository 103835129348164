import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Radio, Select, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../components/Button";
import { PinCodeDialog } from "../../components/Dialog/pincode-dialog";
import { transfertSearchSenderAsync, transfertSearchReceiverAsync, transfertNoRegisterAsync, transfertClearState, transferFeesAsync } from "./slice/transfert-slice";
import { getTransactionAsync, transactionClearState } from "../../redux/transaction/transaction-slice";
import { PrinterOutlined } from '@ant-design/icons';
import { API_ROOT } from "../../utils/api-config";

export function TransfertPage() {
    const dispatch = useDispatch();
    const dialog = useSelector((state) => state.dialog);
    const transfert = useSelector((state) => state.transfert);
    const { currentAgent } = useSelector((state) => state.auth);
    const transactions = useSelector((state) => state.transaction);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [dataSaved, setDataSaved] = useState("")

    const handleSearchSender = async ({ phone_number }) => {
        const data = {
            "country": currentAgent.country,
            "phone_number": phone_number,
            "pincode": "",
            "token": currentAgent.access_token
        }
        dispatch(transfertSearchSenderAsync(data));
    }

    const handleSearchReceiver = async (value) => {
        const data = {
            "country": currentAgent.country,
            "country_receiver": value.country_receiver,
            "pincode": "",
            "token": currentAgent.access_token,
            "tomsisdn": value.phone_receiver
        }
        dispatch(transfertSearchReceiverAsync(data))

        setDataSaved({
            "phone_receiver": value.phone_receiver,
            "phone_sender": value.phone_number,
            "country_receiver": value.country_receiver,
            "first_name": transfert?.sender?.first_name ?? value.firstname,
            "last_name": transfert?.sender?.last_name ?? value.lastname,
            "idType": transfert?.sender?.id_type ?? value.idType,
            "idNumber": transfert?.sender?.id_number ?? value.idNumber,
            "motif": value.motif
        })
    }


    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "country_receiver": value.country_receiver,
            "montant": value.montant,
            "operation": "TRANSFER_REGISTER",
            "pincode": "",
            "receiver": value.phone_receiver,
            "service": "",
            "token": currentAgent.access_token
        }
        setDataSaved({
            ...dataSaved,
            "motif": value.motif,
            "montant": value.montant,
            "phone_receiver": value.phone_receiver,
            "firstname_receiver": value.firstname_receiver,
            "lastname_receiver": value.lastname_receiver
        })
        dispatch(transferFeesAsync(data))
    }


    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "cashin",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
    }

    const handleNoregister = async (pin) => {
        console.log("dataSaved:", dataSaved);
        const data = {
            "agent_pin": pin,
            "amount": dataSaved.montant,
            "country": currentAgent.country,
            "country_receiver": dataSaved.country_receiver,
            "customer_first_name": dataSaved.first_name,
            "customer_last_name": dataSaved.last_name,
            "customer_identity_number": dataSaved.idNumber,
            "customer_identity_type": dataSaved.idType,
            "customer_phone_number": dataSaved.phone_receiver,
            "motif": dataSaved.motif,
            "recipient_first_name": dataSaved.firstname_receiver,
            "recipient_last_name": dataSaved.lastname_receiver,
            "token": currentAgent.access_token,
            "tomsisdn": dataSaved.phone_receiver
        }
        dispatch(transfertNoRegisterAsync(data))
    }

    const getPin = (pin) => handleNoregister(pin)


    const resumeJson = {
        "title": "Transfert d'Argent",
        "message": `Vous êtes sur le point d´éffectuer un transfert d´un montant de ${dataSaved.montant} F CFA `,
        "fees": transfert.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            render: (record) => <span><a href={API_ROOT + "api/imprime/transfert/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }

    ];

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(transfertClearState())
        };
    }, [dispatch]);

    useEffect(() => {
        if (transfert.isSuccess) {
            dispatch(transfertClearState())
            // form.resetFields()
        }
    }, [transfert, dispatch]);


    useEffect(() => {
        form.setFieldsValue({
            firstname: transfert?.sender?.first_name,
            lastname: transfert?.sender?.last_name,
            idType: transfert?.sender?.id_type,
            idNumber: transfert?.sender?.id_number,
            firstname_receiver: transfert?.receiver?.first_name,
            lastname_receiver: transfert?.receiver?.last_name
        });
    }, [form, transfert]);


    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="TRANFERT D'ARGENT" />
            <Spin tip="Chargement..." spinning={transfert.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form name="basic" layout="vertical" onFinish={transfert.isSearchReceiver ? handleFees : transfert.isSearchSender ? handleSearchReceiver : handleSearchSender} >
                                <div>
                                    <h3>Envoyeur</h3>
                                    <Form.Item label="Numéro de téléphone de l'envoyeur" name="phone_number" rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                        <Input className="form-control" />
                                    </Form.Item>
                                </div>
                                {transfert.isSearchSender &&
                                    <div>
                                        <Row gutter={16}>
                                            <Col lg={12}>
                                                <Form.Item label="Nom client" name="firstname" rules={[{ required: transfert.isNoRegister, message: "Champs requis" }]}>
                                                    <Input className="form-control" prefix={<UserOutlined />} placeholder={transfert?.sender?.first_name} disabled={!transfert.isNoRegister} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Item label="Prénom client" name="lastname" rules={[{ required: transfert.isNoRegister, message: "Champs requis" }]}>
                                                    <Input className="form-control" prefix={<UserOutlined />} placeholder={transfert?.sender?.last_name} disabled={!transfert.isNoRegister} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col lg={24}>
                                                <Form.Item label="Type de pièce d'identité" name="idType" rules={[{ required: transfert.isNoRegister, message: "Champs requis" }]}>
                                                    <Radio.Group disabled={!transfert.isNoRegister}>
                                                        <Radio value="1">Carte d'identité</Radio>
                                                        <Radio value="2">Passeport</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col lg={24}>
                                                <Form.Item label="Numéro de pièce d'identité" name="idNumber" rules={[{ required: transfert.isNoRegister, message: "Champs requis" }]}>
                                                    <Input className="form-control" placeholder={transfert?.sender?.id_number} disabled={!transfert.isNoRegister} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div>
                                            <h3>Destinataire</h3>
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Form.Item label="Selèctionnez l'Operateur" name="country_receiver" rules={[{ required: true, message: "Champs requis" }]}>
                                                        <Select>
                                                            <Option value="sn">Wizall Money Sénégal</Option>
                                                            <Option value="ml">Wizall Money Mali</Option>
                                                            <Option value="bf">Wizall Money Bukina Faso</Option>
                                                            <Option value="ci">Côte d'Ivoire</Option>
                                                            <Option value="aj">Adja Money Sénégal</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item label="Numéro de téléphone du destinataire" name="phone_receiver" rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                                <Input className="form-control" />
                                            </Form.Item>
                                        </div>
                                        {transfert.isSearchReceiver &&
                                            <div>
                                                <Row gutter={16}>
                                                    <Col lg={12}>
                                                        <Form.Item label="Nom client" name="firstname_receiver" rules={[{ required: transfert.isNoRegisterReceiver, message: "Champs requis" }]}>
                                                            <Input className="form-control" placeholder={transfert?.receiver?.first_name} prefix={<UserOutlined />} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Item label="Prénom client" name="lastname_receiver" rules={[{ required: transfert.isNoRegisterReceiver, message: "Champs requis" }]}>
                                                            <Input className="form-control" placeholder={transfert?.receiver?.last_name} prefix={<UserOutlined />} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item label="Motif du Transfert" name="motif" rules={[{ required: true, message: "Champs requis" }]}>
                                                            <Select>
                                                                <Option value="Assistance Familiale">Assistance Familiale</Option>
                                                                <Option value="Frais Medicaux">Frais Medicaux</Option>
                                                                <Option value="Frais Scolaire">Frais Scolaire</Option>
                                                                <Option value="Oeuvre et Don">Oeuvre et Don</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Form.Item label="Montant" name="montant" rules={[{ pattern: new RegExp("^[0-9]+$"), message: "Montant incorrect" }, { required: true, message: 'Veuillez saisir un montant' }]}>
                                                    <Input className="form-control" />
                                                </Form.Item>
                                            </div>}
                                    </div>
                                }
                                <Form.Item>
                                    <CustomBtn title={transfert.isSearchReceiver ? "Valider" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transactions.data}
                                locale={{
                                    emptyText: (transactions.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transactions.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div >
    );
}
export default TransfertPage;

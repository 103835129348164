import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../components/Button";
import { PinCodeDialog } from "../../components/Dialog/pincode-dialog";
import { getFeesAsync } from "../../redux/fees/fees-slice";
import { catalogAsync, purchaseAsync, billMintrouteClearState } from "../../redux/bills/bill-mintroute-slice";
import minterouteData from '../../utils/mintrouteData';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getTransactionAsync } from "../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../utils/api-config";
import { PrinterOutlined } from '@ant-design/icons';

const { Option } = Select;

export function BillMinteroutePage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const minteroute = useSelector((state) => state.minteroute);
    const [zones, setZones] = useState([]);
    const [brands, setBrands] = useState([]);
    const [denominations, setDenominations] = useState([]);
    const [denominationId, setDenominationId] = useState();
    const [amount, setAmount] = useState("");
    const [card, setCard] = useState("");
    const [layout, setLayout] = useState(false);
    const [phone, setPhone] = useState("")
    const transaction = useSelector((state) => state.transaction);


    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": amount,
            "operation": "WW2B",
            "pincode": "",
            "receiver": value.costumer_number,
            "service": "MINTROUTE",
            "token": currentAgent.access_token,
            "denomination_id": value.denomination_id
        }
        setPhone(value.costumer_number)
        dispatch(getFeesAsync(data))
    }


    const handlePay = async (pin) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "agent_pin": pin,
            "amount": amount,
            "country": currentAgent.country,
            "receveurmsisdn": phone,
            "receiver_msisdn": phone,
            "denomination_id": denominationId,
            "token": currentAgent.access_token
        }
        dispatch(purchaseAsync(data))
    }

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "virtual_card_mintroute"
        }
        dispatch(getTransactionAsync(data))
    }

    const getPin = (pin) => handlePay(pin)

    const resumeJson = {
        "title": "Paiement jeux et tv",
        "message": `Vous allez effectuer un payement d'un montant de ${amount} FCFA`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        const data = {
            "agent_pin": "",
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(catalogAsync(data))
    }, [dispatch, currentAgent]);

    const handleClickCard = (card) => {
        const filterByBrand = minteroute.catalog.filter(item => item.brand === card.value);
        const groupByZone = [...new Set(filterByBrand.map(item => item.zone))]
        setCard(card)
        setZones(groupByZone)
        setBrands(filterByBrand);
        setLayout(true)
    }

    const handleChangeZone = (value) => {
        const data = brands.filter(item => item.zone === value)
        setDenominations(data);
    }

    const handleChangeDenomination = (value) => {
        const data = JSON.parse(value);
        setAmount(data.amount)
        setDenominationId(data.denomination_id)
        console.log("id", data)
    }

    useEffect(() => {
        if (minteroute.isSuccess) {
            dispatch(billMintrouteClearState())
            form.resetFields()
        }
    }, [minteroute, dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="TV & JEUX" />
            <Spin tip="Chargement..." spinning={frais.isFetching || minteroute.isFetching || minteroute.isFetching}>
                <Row gutter={16} className="mintroute" >
                    <Col lg={12} xs={24}>
                        <Card>
                            {!layout ?
                                <div>
                                    <Col lg={24} xs={24}>
                                        <Row gutter={16}>
                                            {
                                                minterouteData.map((item) => (
                                                    <Col lg={6} className="blc" onClick={() => handleClickCard(item)}>
                                                        <Card><img src={`assets/img/${item.image}`} alt="img" style={{ width: '100%' }} /> </Card>
                                                        <p>{item.title}</p>
                                                    </Col>))
                                            }
                                        </Row>
                                    </Col>
                                </div> :
                                <div>
                                    <Button className="btn-secondary" icon={<ArrowLeftOutlined />} onClick={() => setLayout(false)}>Retour</Button>
                                    <Col lg={8} style={{ textAlign: 'center', margin: 'auto' }}>
                                        <img src={`assets/img/${card.image}`} alt="img" style={{ width: '100%' }} />
                                        <p>{card.title}</p>
                                    </Col>
                                    <Form
                                        name="basic"
                                        form={form}
                                        layout="vertical"
                                        initialValues={{ remember: true }}
                                        onFinish={handleFees}
                                    >
                                        <Form.Item
                                            label="Numéro du client"
                                            name="costumer_number"
                                            rules={[{ required: true, message: "Veuillez saisir un numéro" }]}
                                        >
                                            <Input className="form-control" placeholder="Numero du client" />
                                        </Form.Item>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item label="Nom client" name="firstname" rules={[{ required: true, message: "Champs requis" }]}>
                                                    <Input className="form-control" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Prénom client" name="lastname" rules={[{ required: true, message: "Champs requis" }]}>
                                                    <Input className="form-control" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {
                                            zones.length > 1 ?
                                                <>
                                                    <Form.Item label="Selectionner la zone">
                                                        <Select placeholder="Selectionner la zone" onChange={handleChangeZone}>
                                                            <>
                                                                {zones.map(element => {
                                                                    return (
                                                                        <Option value={element}>
                                                                            {element}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Selectionner le montant">
                                                        <Select placeholder="Selectionner le montant" onChange={handleChangeDenomination}>
                                                            <>
                                                                {denominations.map(item => {
                                                                    console.log(item)
                                                                    return (
                                                                        <Option value={JSON.stringify(item)}>

                                                                            {item.denomination}

                                                                        </Option>
                                                                    );
                                                                })}
                                                            </>
                                                        </Select>
                                                    </Form.Item>
                                                </> :
                                                <>
                                                    <Form.Item label="Selectionner le montant">
                                                        <Select placeholder="Selectionner le montant" onChange={handleChangeDenomination}>
                                                            <>
                                                                {brands.map(item => {
                                                                    console.log(item)
                                                                    return (
                                                                        <Option value={JSON.stringify(item)}>

                                                                            {item.denomination}

                                                                        </Option>
                                                                    );
                                                                })}
                                                            </>
                                                        </Select>
                                                    </Form.Item>
                                                </>
                                        }
                                        <Form.Item>
                                            <CustomBtn title="Valider" />
                                        </Form.Item>
                                    </Form>
                                </div>}
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillMinteroutePage;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const catalogAsync = createAsyncThunk(
    'billMintroute/catalogAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/mintroute/catalog/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error.response.data", error.response.data.message)
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const purchaseAsync = createAsyncThunk(
    'billMintroute/purchaseAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/mintroute/purchase/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const billMintrouteSlice = createSlice({
    name: 'billMintroute',
    initialState: {
        status: 'idle',
        isFetching: false,
        catalog: []
    },
    reducers: {
        billMintrouteClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
            state.data = "";
        },
    },
    extraReducers: {
        [catalogAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = false;

        },
        [catalogAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.catalog = payload.data
        },
        [catalogAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [purchaseAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [purchaseAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [purchaseAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        }
    }
});

export const { billMintrouteClearState } = billMintrouteSlice.actions;
export default billMintrouteSlice.reducer;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Avatar, Button, Alert, Result, Spin } from 'antd';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { setPinAsync } from '../../redux/auth/auth-slice';
import ReactCodesInput from "react-codes-input";
import { removeAgentState } from "../../redux/loadStorage";

export function PinSettedDialog() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentAgent, isFetching, isPinSettedSuccess } = useSelector((state) => state.auth);
    const [pinCode, setPinCode] = useState("");
    const [pinCodeConfirm, setPinCodeConfirm] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handlePinChange = pinCode => {
        setPinCode(pinCode);
    };

    const handlePinConfirm = pinCodeConfirm => {
        setPinCodeConfirm(pinCodeConfirm);
    };

    const handleSubmit = async () => {
        if (pinCode === pinCodeConfirm) {
            const data = {
                "agent_msisdn": currentAgent.phone,
                "agent_pin": "",
                "new_pin": pinCode,
                "new_pin_confirm": pinCodeConfirm,
                "country": currentAgent.country,
                "token": currentAgent?.access_token
            }
            dispatch(setPinAsync(data));
        }
        else {
            setErrorMessage("Les deux mots de passe ne sont pas conformes");
        }
    }

    const handlelogout = async () => {
        history.push("/login")
        removeAgentState()
    }

    return (
        <Modal
            visible={true}
            centered
            footer={null}
            closable={false}
            mask
            maskClosable={false}
            maskStyle={{ backgroundColor: '#50B5C7F5' }}
            className="lockscreen">
            <div className="content">
                {isPinSettedSuccess ?
                    <Result
                        status="success"
                        title="Votre Code pin a été defini avec succès"
                        extra={[<Button className="btn btn-secondary" onClick={() => handlelogout()}>Connection</Button>]} />
                    :
                    <Spin spinning={isFetching}>
                        <Avatar size={64} icon={<UserOutlined />} />
                        <h4>{currentAgent.username}</h4>
                        <p>Veuillez definir votre code pin</p> <br />
                        <Row gutter={16}>
                            <Col lg={24} xs={24}>
                                <>
                                    <p style={{ padding: '5px 5px' }}>Entrez votre nouveau code PIN</p>
                                    <div className="pincode1">
                                        <ReactCodesInput
                                            initialFocus={false}
                                            id="pinCode"
                                            name="pinCode"
                                            type="pinCode"
                                            hide={true}
                                            codeLength={4}
                                            onChange={handlePinChange}
                                            value={pinCode}
                                        />
                                    </div>
                                </>
                                <br />
                                <>
                                    <p style={{ padding: '5px 5px' }}>Confirmer votre code PIN </p>
                                    <div className="pincode1">
                                        <ReactCodesInput
                                            initialFocus={false}
                                            id="pinCodeConfirm"
                                            type="password"
                                            hide={true}
                                            codeLength={4}
                                            onChange={handlePinConfirm}
                                            value={pinCodeConfirm}
                                        />
                                    </div>
                                </>
                                <br />

                                {errorMessage &&
                                    <Alert
                                        message=""
                                        description={errorMessage}
                                        type="warning"
                                        showIcon
                                        closable
                                    />}
                                <Button
                                    style={{ margin: '5px' }}
                                    className="btn btn-lg btn-secondary"
                                    onClick={handleSubmit}> Valider </Button>
                            </Col>
                        </Row>
                    </Spin>
                }
            </div>
        </Modal>
    );
}
export default PinSettedDialog;

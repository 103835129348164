import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
// import { API_ROOT } from '../../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const cardSearchAsync = createAsyncThunk(
    'card/cardSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        console.log("payload:", payload)
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/agent/nfc/info/customer/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload
            })
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const cardSearchSlice = createSlice({
    name: 'cardSearch',
    initialState: {
        status: 'idle',
        isFetching: false,
        data: ""
    },
    reducers: {
        cardClearState: (state) => {
            state.status = 'idle';
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [cardSearchAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [cardSearchAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.data = payload
        },
        [cardSearchAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        }
    }
});

export const { cardClearState } = cardSearchSlice.actions;
export default cardSearchSlice.reducer;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const airtimeOrangeAsync = createAsyncThunk(
    'airtime/airtimeOrangeAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/airtime/reload/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const airtimeFreeAsync = createAsyncThunk(
    'airtime/airtimeFreeAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/airtime/reload/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const airtimeExpressoAsync = createAsyncThunk(
    'airtime/airtimeExpressoAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/airtime/reload/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const airtimePromobileAsync = createAsyncThunk(
    'airtime/airtimePromobileAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/promobile/reload/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Rchargement  effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const airtimeBfAsync = createAsyncThunk(
    'airtime/airtimePromobileAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/bf/airtime/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Rchargement  effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const airtimeReloadAsync = createAsyncThunk(
    'airtime/airtimeReloadAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/airtime/reload/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const airtimeMaliAsync = createAsyncThunk(
    'airtime/airtimeMaliAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/ml/airtime/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const airtimeCiAsync = createAsyncThunk(
    'airtime/airtimeMaliAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/ci/airtime/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const airtimeSlice = createSlice({
    name: 'airtime',
    initialState: {
        status: 'idle',
        isFetching: false,
        isSuccess: false,
    },
    reducers: {
        airtimeClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
        },
    },
    extraReducers: {
        [airtimeOrangeAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [airtimeOrangeAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [airtimeOrangeAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [airtimeFreeAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [airtimeFreeAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [airtimeFreeAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [airtimeExpressoAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [airtimeExpressoAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [airtimeExpressoAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [airtimePromobileAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [airtimePromobileAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [airtimePromobileAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },


        [airtimeBfAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },

        [airtimeBfAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },

        [airtimeBfAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [airtimeMaliAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },

        [airtimeMaliAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [airtimeMaliAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [airtimeCiAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },

        [airtimeCiAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [airtimeCiAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },
    }
});

export const { airtimeClearState } = airtimeSlice.actions;
export default airtimeSlice.reducer;

import React, { useEffect } from "react";
import { Modal, Button, Form, Avatar, Input, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { showDialog } from '../../redux/dialog/dialog-slice';
import { loginOauthAsync } from '../../redux/auth/auth-slice';
import { useHistory } from "react-router-dom";
import { removeAgentState } from "../../redux/loadStorage";


export function LockScreen() {
    const dispatch = useDispatch();
    const { currentAgent, status, error, errorMessage } = useSelector((state) => state.auth);
    let history = useHistory();
    const handleSubmit = async (value) => {
        const data = {
            "username": currentAgent.username,
            "password": value.password,
            "country": currentAgent.country,
        }
        dispatch(loginOauthAsync(data));
    }

    const handlelogout = async () => {
        removeAgentState()
        history.push("/login")
    }



    useEffect(() => {
        if (status === "success")
            dispatch(showDialog({ isLockscreen: false }));
    }, [dispatch, status]);

    return (
        <Modal
            visible={true}
            centered
            footer={null}
            closable={false}
            mask
            maskClosable={false}
            maskStyle={{ backgroundColor: '#50B5C7F5' }}
            className="lockscreen"
        >
            <div className="content">
                <Avatar size={64} icon={<UserOutlined />} />
                <h4>{currentAgent?.username}</h4>
                <p>Vous avez été déconnecté automatiquement.</p>
                <p>Veuillez saisir votre mot de passe pour continuer.</p>

                <Form
                    style={{ margin: '20px 40px' }}
                    name="basic"
                    onFinish={handleSubmit}>
                    {error && (
                        <Alert
                            message={error}
                            type="error"
                            className="alert-error" />
                    )}

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Champs requis' }]}>
                        <Input.Password
                            className="form-control"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Mot de passe"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn btn-lg btn-secondary"
                            loading={(status === 'pending') ? true : false}> Connexion </Button>
                    </Form.Item>
                    <Form.Item>
                        <li style={{ color: 'gray' }} className="ant-menu-item default-item" onClick={() => handlelogout()}>
                            <span className="ant-menu-title-content">DECONNEXION</span>
                        </li>

                    </Form.Item>
                </Form>
                {status === "failed" && (
                    <>
                        <Alert
                            message={errorMessage}
                            type="error"
                            className="alert-error"
                            showIcon
                        />
                    </>
                )}
            </div>
        </Modal>
    );
}
export default LockScreen;

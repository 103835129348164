import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { UserOutlined, DollarOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../components/Button";
import { PinCodeDialog } from "../../components/Dialog/pincode-dialog";
import { costumerSearchAsync, costumerClearState } from "../../redux/costumer-search/costumer-search-slice";
import { getFeesAsync } from '../../redux/fees/fees-slice';
import { cashinAsync, cashinClearState } from "../../redux/cashin/cashin-slice";
import { getTransactionAsync, transactionClearState } from "../../redux/transaction/transaction-slice";
import { PrinterOutlined } from '@ant-design/icons';
import { API_ROOT } from "../../utils/api-config";


export function CashinPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const costumer = useSelector((state) => state.costumer);
    const transaction = useSelector((state) => state.transaction);
    const cashin = useSelector((state) => state.cashin);
    const [montant, setMontant] = useState("");
    const [receiver, setReceiver] = useState("");

    const handleSearch = async (value) => {
        const data = {
            "phone_number": value.phone_number,
            "pincode": "",
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(costumerSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.amount,
            "operation": "C Bon Costumer",
            "pincode": "",
            "receiver": value.phone_number,
            "token": currentAgent.access_token
        }
        setReceiver(value.phone_number)
        setMontant(value.amount)
        dispatch(getFeesAsync(data))
    }

    const handleCashin = async (pin) => {
        const data = {
            "token": currentAgent.access_token,
            "msisdn": receiver,
            "pincode": pin,
            "amount": montant,
            "country": currentAgent.country
        }
        dispatch(cashinAsync(data))
    }

    const getPin = (pin) => handleCashin(pin)

    form.setFieldsValue({
        firstname: costumer?.data.first_name,
        lastname: costumer?.data.last_name
    });

    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "cashin",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
        console.log("transaction", transaction)
    }


    const resumeJson = {
        "title": "Faire un Dépôt",
        "message": `Vous êtes sur le point d´éffectuer un dépôt à disposition d´un montant de ${montant}F CFA vers le numéro ${receiver}`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/transfert/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (cashin.isSuccess) {
            dispatch(costumerClearState())
            dispatch(cashinClearState())
            form.resetFields()
        }
    }, [cashin, dispatch, form]);


    useEffect(() => {
        return () => {
          dispatch(transactionClearState())
            dispatch(cashinClearState())
        };
    }, [dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}

            <PageHeader title="Effectuer Un Dépôt" />
            <Spin tip="Chargement..." spinning={costumer.isFetching || frais.isFetching || cashin.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={costumer.status === "success" ? handleFees : cashin.status === "success" ? handleCashin : handleSearch}>
                                <Form.Item
                                    label="Numero du client"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero du client"
                                    />
                                </Form.Item>
                                {(costumer.status === "success") && (
                                    <div>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Nom client"
                                                    name="firstname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Prénom client" name="lastname">
                                                    <Input className="form-control" prefix={<UserOutlined />} disabled />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            label="Montant"
                                            name="amount"
                                            rules={[{ pattern: new RegExp("^[0-9]+$"), message: "Montant incorrect" }, { required: true, message: 'Veuillez saisir un montant' }]}>
                                            <Input
                                                className="form-control"
                                                prefix={<DollarOutlined />}
                                                placeholder="Montant" />
                                        </Form.Item>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={costumer.status === "success" ? "Valider" : cashin.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default CashinPage;
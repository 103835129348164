import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Col, Alert, Select } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { clearState, loginOauthAsync } from "../../redux/auth/auth-slice";
import { getBackgroundAsync } from "../../redux/features/feature-slice";
import { country } from "../../utils/globalVars";

const { Option } = Select;

export function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { background } = useSelector((state) => state.feature);

  const handleSubmit = async (value) => {
    const data = {
      username: value.username,
      password: value.password,
      country: value.country ?? country(),
    };
    dispatch(loginOauthAsync(data));
  };

  useEffect(() => {
    if (auth.status === "success" && auth.currentAgent !== undefined) {
      dispatch(clearState());
      history.push("/home");
    }
  }, [dispatch, auth, history]);

  useEffect(() => {
    dispatch(
      getBackgroundAsync({
        application: "Web Agent",
        type: "background",
        country: country(),
      })
    );
  }, [dispatch]);

  return (
    <>
      <div
        className="login-page"
        style={{
          backgroundImage:
            background === "" || background === undefined
              ? `url(assets/img/background-default.png)`
              : `url(https://assets.wizall.com/image/${background})`,
        }}
      >
        <Col
          lg={6}
          xs={16}
          style={{ marginLeft: "auto", marginRight: "200px" }}
        >
          <div className="card">
            <Form
              style={{ margin: "40px" }}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
            >
              <h2>Bienvenue</h2>
              {auth.status === "failed" && (
                <Alert
                  message={auth.errorMessage}
                  type="error"
                  className="alert-error"
                  showIcon
                />
              )}
              <Form.Item
                name="country"
                rules={[{ required: true, message: "Champs requis" }]}
              >
                <Select placeholder="Selectionner le pays">
                  <Option value="sn">
                    <img src="assets/img/flag-sn.png" alt="img" width={20} />{" "}
                    <span>Sénégal</span>
                  </Option>
                  <Option value="ci">
                    <img src="assets/img/flag-ci.png" alt="img" width={20} />{" "}
                    <span>Cote d'ivoire</span>
                  </Option>
                  <Option value="bf">
                    <img src="assets/img/flag-bf.png" alt="img" width={20} />{" "}
                    <span>Burkina Faso</span>
                  </Option>
                  <Option value="ml">
                    <img src="assets/img/flag-ml.png" alt="img" width={20} />{" "}
                    <span>Mali</span>
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="username"
                rules={[{ required: true, message: "Champs requis" }]}
              >
                <Input
                  className="form-control"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Login"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Champs requis" }]}
              >
                <Input.Password
                  className="form-control"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Mot de passe"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn btn-secondary"
                  loading={auth.isFetching}
                >
                  Connexion
                </Button>
              </Form.Item>
              <span>
                <Link to="/activate">Premiere connexion / </Link>
                <Link to="/activate">Mot de passe oublié</Link>
              </span>
            </Form>
          </div>
        </Col>
      </div>
    </>
  );
}
export default LoginPage;

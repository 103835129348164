import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const billDstvSearchAsync = createAsyncThunk(
    'billDstv/billDstvSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/dstv/get_customer_info/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            const dataToken = {
                "token": payload.token,
                "country": payload.country,
                ...response.data,
            }
            dispatch(billDstvCatalogAsync(dataToken));
            return response.data
        } catch (error) {
            console.log("error.response.data", error.response.data.message)
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const billDstvCatalogAsync = createAsyncThunk(
    'billDstv/billDstvCatalogAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/dstv/catalog/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error.response.data", error.response.data.message)
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const billDstvPayAsync = createAsyncThunk(
    'billDstv/billDstvPayAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/dstv/pay_dstv/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const billDstvSlice = createSlice({
    name: 'billDstv',
    initialState: {
        status: 'idle',
        isFetching: false,
        data: "",
        options: [],
        formules: []
    },
    reducers: {
        billDstvClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
            state.data = "";
        },
    },
    extraReducers: {
        [billDstvSearchAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billDstvSearchAsync.fulfilled](state, { payload }) {
            state.data = payload.data
        },
        [billDstvSearchAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },
        [billDstvCatalogAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billDstvCatalogAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.formules = payload.formulas
            state.options = payload.addons
        },
        [billDstvCatalogAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [billDstvPayAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billDstvPayAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [billDstvPayAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        }
    }
});

export const { billDstvClearState } = billDstvSlice.actions;
export default billDstvSlice.reducer;

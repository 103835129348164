import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Button, Menu } from 'antd';
import { InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getBalanceAsync } from '../../redux/journalSlice';
import { removeAgentState } from "../../redux/loadStorage";
import { getProductAsync } from "../../redux/features/feature-slice";
import packageJson from '../../../package.json';

const { Sider } = Layout;

export function Sidebar() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isBalance, setIsBalance] = useState(false);
    const { currentAgent } = useSelector((state) => state.auth);
    const history = useHistory();
    const { balance, isFetchingBalance } = useSelector((state) => state.journal);

    const handleSolde = useCallback(() => {
        const data = {
            "country": currentAgent?.country,
            "pincode": "",
            "token": currentAgent?.access_token
        }
        dispatch(getBalanceAsync(data));
    }, [dispatch, currentAgent])

    useEffect(() => {
        dispatch(getProductAsync({ application: "Web Agent", type: "product", country: currentAgent?.country }))
    }, [dispatch, currentAgent])

    const handlelogout = async () => {
        removeAgentState()
        history.push("/login")
    }

    return (
        <>
            <Sider className="sidebar"
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => { console.log(broken) }}
                onCollapse={(collapsed, type) => { console.log(collapsed, type) }}>
                <div className="avatar">
                    <div className="flag" style={{ backgroundImage: `url("./assets/img/flag-${currentAgent?.country}.png")` }} />
                    <div>
                        <p><strong>{currentAgent?.username}</strong></p>
                        <span>{currentAgent?.phone}</span>
                    </div>
                </div>
                <div className="solde">
                    {isBalance ?
                        <Button className="btn-secondary" onClick={() => { handleSolde(); setIsBalance(true) }}>{isFetchingBalance ? "Chargement..." : `Solde: ${balance} F CFA`} </Button> :
                        <Button className="btn-secondary" onClick={() => { handleSolde(); setIsBalance(true) }}>{isFetchingBalance ? "Chargement..." : "Voir mon solde"} </Button>
                    }
                </div>
                <Menu selectedKeys={[location.pathname]} defaultSelectedKeys={["/dashboard"]} mode="inline">
                    <Menu.Item key={"/home"} icon={<img src="/assets/img/icon-home.png" alt="icon" className="icon" />}>
                        <NavLink to={"/home"} className="menu-link"><span>Accueil</span></NavLink>
                    </Menu.Item>
                    {currentAgent.username.includes('USR') ? "" :
                        <Menu.Item key={"/cashout-provision"} disabled={false} icon={<img src="/assets/img/icon-cashout.png" alt="icon" className="icon" />}>
                            <NavLink to={"/cashout-provision"} className="menu-link"><span>ORDRE DE RETRAIT</span></NavLink>
                        </Menu.Item>
                    }

                    <Menu.Item key={"/transaction"} icon={<img src="/assets/img/icon-transaction.png" alt="icon" className="icon" />}>
                        <NavLink to={"/transaction"} className="menu-link"><span>Historique</span></NavLink>
                    </Menu.Item>

                    <Menu.Item key={"/pincode-reset"} icon={<img src="/assets/img/icon-pincode-reset.png" alt="icon" className="icon" />}>
                        <NavLink to={"/pincode-reset"} className="menu-link"><span>Modifier code pin</span></NavLink>
                    </Menu.Item>

                    <li className="ant-menu-item default-item" onClick={() => handlelogout()}>
                        <LogoutOutlined /> <span className="ant-menu-title-content">DECONNEXION</span>
                    </li>
                    <li className="ant-menu-item default-item">
                        <InfoCircleOutlined /> <span className="ant-menu-title-content"> Version: {packageJson.version} </span>
                    </li>
                </Menu>
            </Sider>
        </>
    );
}

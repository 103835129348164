import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { agentClearState } from "../../../redux/agent-search/agent-search-slice";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { billDiobassSearchAsync, billDiobassPayAsync, billDiobassClearState } from "../../../redux/bills/bill-diobass-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";


export function BillDiobassPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const billDiobass = useSelector((state) => state.billDiobass);
    const [receiver, setReceiver] = useState("");
    const [numReservation, setNumReservation] = useState("");
    const transactions = useSelector((state) => state.transaction);
    const { Option } = Select;
    const [facture, setFacture] = useState("");

    const handleChangeFactures = (value) => {
        setFacture(JSON.parse(value))
    };


    let montant = parseInt(facture.amount);


    const handleSearch = async (value) => {
        const data = {
            "agent_msisdn": currentAgent.phone,
            "number": value.number,
            "pincode": "",
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        setNumReservation(value.number)
        dispatch(billDiobassSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": montant,
            "operation": "DIOBASS",
            "pincode": "",
            "receiver": value.phone_number,
            "token": currentAgent.access_token
        }
        setReceiver(value.phone_number)
        dispatch(getFeesAsync(data))
    }

    const handlePay = async (pin) => {
        const data = {
            "token": currentAgent.access_token,
            "agent_msisdn": currentAgent.phone,
            "receiver_msisdn": receiver,
            "agent_pin": pin,
            "amount": montant,
            "country": currentAgent.country,
            "reservation_number": numReservation,
            "position": facture.position,
            "date_deadline": facture.date_deadline,
            "comment": numReservation + ";" + receiver + ";" + facture.date_deadline
        }
        dispatch(billDiobassPayAsync(data))
    }


    const handleTransaction = async () => {
        const data = {
            "pincode": "",
            "count": 10,
            "type": "provisions",
            "country": currentAgent.country,
            "token": currentAgent.access_token
        }
        dispatch(getTransactionAsync(data))
    }

    const getPin = (pin) => handlePay(pin)

    form.setFieldsValue({
        firstname: billDiobass.data[0]?.first_name,
        lastname: billDiobass.data[0]?.last_name,
    });


    const resumeJson = {
        "title": "Logement Diobass",
        "message": `Vous allez payez la facture de numéro de reservation : d´un montant de ${montant}F CFA `,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billDiobassClearState())
        };
    }, [dispatch, form]);

    useEffect(() => {
        if (billDiobass.isSuccess) {
            dispatch(agentClearState())
            dispatch(billDiobassClearState())
            form.resetFields()
        }
    }, [billDiobass, dispatch, form]);


    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}

            <PageHeader title="Logement Diobass" />
            <Spin tip="Chargement..." spinning={billDiobass.isFetching || frais.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={billDiobass.status === "success" ? handleFees : billDiobass.status === "success" ? handlePay : handleSearch}>
                                <Form.Item
                                    label="Numero du client"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero du client"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Numero de reservation"
                                    name="number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de l'agent"
                                    />
                                </Form.Item>


                                {(billDiobass.status === "success") && (
                                    <div>

                                        <Form.Item
                                            label="prenom du client "
                                            name="firstname">
                                            <Input className="form-control" prefix={<UserOutlined />} disabled />
                                        </Form.Item>

                                        <Form.Item
                                            label="nom du client "
                                            name="lastname">
                                            <Input className="form-control" prefix={<UserOutlined />} disabled />
                                        </Form.Item>

                                        <Form.Item label="Formule">
                                            <Select placeholder="Factures" onChange={handleChangeFactures}>
                                                {billDiobass.data[0].factures.map(element => {
                                                    return (
                                                        <Option value={JSON.stringify(element)}>
                                                            {element.reference_facture} - {element.amount} FCFA
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </div>

                                )}
                                <Form.Item>
                                    <CustomBtn title={billDiobass.status === "success" ? "Valider" : billDiobass.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Table
                                dataSource={transactions.data}
                                locale={{
                                    emptyText: (transactions.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transactions.isFetching}
                                columns={columns} />

                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillDiobassPage;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const agentSearchAsync = createAsyncThunk(
    'agent/agentSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        console.log("payload:", payload)
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/agent/search/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload
            })
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const agentSearchSlice = createSlice({
    name: 'agentSearch',
    initialState: {
        status: 'idle',
        isFetching: false,
        data: ""
    },
    reducers: {
        agentClearState: (state) => {
            state.status = 'idle';
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [agentSearchAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [agentSearchAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.data = payload
        },
        [agentSearchAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        }
    }
});

export const { agentClearState } = agentSearchSlice.actions;
export default agentSearchSlice.reducer;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { showDialog } from '../dialog/dialog-slice';

export const billSeneauSearchAsync = createAsyncThunk(
    'billSeneau/billSeneauSearchAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/sde/bill/get/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error.response.data", error.response.data.message)
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const billSeneauPayAsync = createAsyncThunk(
    'billSeneau/billSeneauPayAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/sde/bill/pay/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(showDialog({ isSuccess: true, successMessage: "Transaction effectuée avec succès" }));
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED") || (error.response.data.message === "Unauthorized"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);


export const billSeneauSlice = createSlice({
    name: 'billSeneau',
    initialState: {
        status: 'idle',
        isFetching: false,
        isSuccess: false
    },
    reducers: {
        billSeneauClearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
        },
    },
    extraReducers: {
        [billSeneauSearchAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billSeneauSearchAsync.fulfilled](state, { payload }) {
            state.status = "success"
            state.isFetching = false
            state.data = payload
        },
        [billSeneauSearchAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [billSeneauPayAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [billSeneauPayAsync.fulfilled](state) {
            state.status = "success"
            state.isFetching = false
            state.isSuccess = true
        },
        [billSeneauPayAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },
    }
});

export const { billSeneauClearState } = billSeneauSlice.actions;
export default billSeneauSlice.reducer;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Table, Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { getFeesAsync } from "../../../redux/fees/fees-slice";
import { billRapidoAsync, billrapidoClearState } from "../../../redux/bills/bill-rapido-slice";
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";

export function BillRapidoPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const frais = useSelector((state) => state.fees);
    const billRapido = useSelector((state) => state.billRapido);
    const [montant, setMontant] = useState("");
    const [phone, setPhone] = useState("")
    const [numrapido, setNumrapido] = useState("")
    const transaction = useSelector((state) => state.transaction);

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": value.montant,
            "operation": "RAPIDO",
            "pincode": "",
            "receiver": value.costumer_number,
            "token": currentAgent.access_token
        }
        setPhone(value.costumer_number)
        dispatch(getFeesAsync(data))
        setMontant(value.montant)
        setNumrapido(value.rapido_number)
    }


    const handleBillrapido = async (pin) => {
        const data = {
            "agent_pin": pin,
            "badge_num": numrapido,
            "country": currentAgent.country,
            "montant": montant,
            "receveurmsisdn": phone,
            "token": currentAgent.access_token
        }
        dispatch(billRapidoAsync(data))
    }
    const getPin = (pin) => handleBillrapido(pin)

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "RAPIDO"
        }
        dispatch(getTransactionAsync(data))
       
    }

    const resumeJson = {
        "title": "Recharge carte Rapido",
        "message": `Vous allez effectuer une RECHARGE RAPIDO d'un montant de ${montant}F CFA vers le numéro ${phone}`,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (billRapido.isSuccess) {
            dispatch(billrapidoClearState())
            form.resetFields()
        }
    }, [billRapido, dispatch, form]);

    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billrapidoClearState())
        };
    }, [dispatch, form]);
    return (
        <div className="default-page" >
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="Recharge Rapido" />
            <Spin tip="Chargement..." spinning={frais.isFetching || billRapido.isFetching || billRapido.isFetching} >
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={handleFees}
                            >

                                <Form.Item
                                    label="Numéro du client"
                                    name="costumer_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero du client"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Numero de la carte rapido"
                                    name="rapido_number"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de la carte rapido"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Montant"
                                    name="montant"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]} >
                                    <Input
                                        className="form-control"
                                        placeholder="Montant" />
                                </Form.Item>

                                <Form.Item>
                                    <CustomBtn title= "Valider" />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                    <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillRapidoPage;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Spin, PageHeader, Select, Table, Button } from 'antd';
import { UserOutlined, PrinterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtn } from "../../../components/Button";
import { PinCodeDialog } from "../../../components/Dialog/pincode-dialog";
import { billDstvSearchAsync, billDstvClearState, billDstvPayAsync } from "../../../redux/bills/bill-dstv-slice";
import { getFeesAsync } from '../../../redux/fees/fees-slice';
import { getTransactionAsync, transactionClearState } from "../../../redux/transaction/transaction-slice";
import { API_ROOT } from "../../../utils/api-config";

export function BillDstvPage() {
    const dispatch = useDispatch();
    const { currentAgent } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const dialog = useSelector((state) => state.dialog);
    const bill = useSelector((state) => state.billDstv);
    const frais = useSelector((state) => state.fees);
    const collecte = useSelector((state) => state.collecte);
    const [formule, setFormule] = useState("");
    const [option, setOption] = useState("");
    const [numCarte, setNumCarte] = useState("");
    const { Option } = Select;
    const transaction = useSelector((state) => state.transaction);


    let montant = parseInt(formule.price) + parseInt(option.price ?? 0);

    const handleChangeFormulas = (value) => setFormule(JSON.parse(value));
    const handleChangeOption = (value) => setOption(JSON.parse(value));

    const handleSearch = async (value) => {
        const data = {
            "tomsisdn": value.tomsisdn,
            "numero_carte": value.num_carte,
            "pincode": "",
            "country": currentAgent.country,
            "token": currentAgent.access_token,
        }
        dispatch(billDstvSearchAsync(data))
    }

    const handleFees = async (value) => {
        const data = {
            "country": currentAgent.country,
            "montant": montant,
            "operation": "COLLECTE",
            "pincode": "",
            "receiver": bill.data.telephone,
            "token": currentAgent.access_token
        }
        setNumCarte(value.num_carte)
        dispatch(getFeesAsync(data))
    }

    const handlePay = async (pin) => {
        const data = {
            "token": currentAgent.access_token,
            "agent_msisdn": currentAgent.phone,
            "agent_pin": pin,
            "amount": montant,
            "country": currentAgent.country,
            "nombre_mois": "1",
            "categorie_client": "CLIENT DSTV",
            "formule_abonnement": formule.nom_formule_abonnement,
            "option_abonnement": option.nom_option_abonnment,
            "numero_carte": numCarte,
            "date_fin": bill?.data.date_fin
        }
        dispatch(billDstvPayAsync(data))
    }

    const getPin = (pin) => handlePay(pin)

    const handleTransaction = async () => {
        const data = {
            "count": 10,
            "country": currentAgent.country,
            "pincode": "",
            "token": currentAgent.access_token,
            "type": "collecte"
        }
        dispatch(getTransactionAsync(data))
       
    }
 

    form.setFieldsValue({
        phone: bill?.data.telephone,
        date: bill?.data.date_fin,
    });

    const resumeJson = {
        "title": "PAIEMENT DSTV",
        "message": `Vous allez effectuer une transaction d'un montant de ${montant}F CFA vers le numero ${bill.data.telephone} `,
        "fees": frais.fees
    }

    const columns = [
        {
            title: 'ID transactions',
            dataIndex: 'id',
        },
        {
            title: 'Dates',
            dataIndex: 'datetransformatted',
        },
        {
            title: 'Téléphones',
            dataIndex: 'receiver',
            responsive: ['lg'],
        },
        {
            title: 'Montants',
            render: (record) => <span>{record.amount} FCFA</span>
        },
        {
            title: 'Imprimer',
            responsive: ['lg'],
            render: (record) => <span><a href={API_ROOT + "api/imprime/factures/" + record.id + "/?token=" + currentAgent.access_token + "&country=" + currentAgent.country + ""} rel="noopener noreferrer" target="_blank">< PrinterOutlined style={{ fontSize: '20px' }} /> </a ></span >
        }
    ];

    useEffect(() => {
        if (bill.isSuccess) {
            dispatch(billDstvClearState())
           
            form.resetFields()
        }
    }, [bill, dispatch, form]);


    useEffect(() => {
        return () => {
            dispatch(transactionClearState())
            dispatch(billDstvClearState())
        };
    }, [dispatch, form]);

    return (
        <div className="default-page">
            {dialog.isPinCode && <PinCodeDialog resume={resumeJson} pinCode={getPin} />}
            <PageHeader title="DSTV" />
            <Spin tip="Chargement..." spinning={bill.isFetching || frais.isFetching}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card>
                            <h1>EFFECTUER UN PAIEMENT DSTV</h1>
                            <Form
                                name="basic"
                                form={form}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={bill.status === "success" ? handleFees : collecte.status === "success" ? handlePay : handleSearch}>
                                <Form.Item
                                    label="numero de carte client"
                                    name="num_carte"
                                    rules={[{ required: true, message: "Veuillez saisir un numéro" }]}>
                                    <Input
                                        className="form-control"
                                        placeholder="Numero de carte client"
                                    />
                                </Form.Item>
                                {(bill.status === "success") && (
                                    <div>
                                        <Form.Item
                                            label="Telephone du client "
                                            name="phone">
                                            <Input className="form-control" prefix={<UserOutlined />} disabled />
                                        </Form.Item>

                                        <Form.Item
                                            label="date echeance"
                                            name="date">
                                            <Input className="form-control" prefix={<UserOutlined />} disabled />
                                        </Form.Item>
                                        <Form.Item label="Formule">
                                            <Select placeholder="Formule" onChange={handleChangeFormulas}>
                                                {bill.formules.map(element => {
                                                    return (
                                                        <Option value={JSON.stringify(element)}>
                                                            {element.nom_formule_abonnement} - {element.price}Fcfa
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label="Option">
                                            <Select placeholder="Option" onChange={handleChangeOption}>
                                                {bill.options.map(element => {
                                                    return (
                                                        <Option value={JSON.stringify(element)}> {element.nom_option_abonnment} - {element.price} Fcfa</Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                )}
                                <Form.Item>
                                    <CustomBtn title={bill.status === "success" ? "Confirmer" : "Chercher"} />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col lg={12} xs={24}>
                    <Card>
                            <Table
                                dataSource={transaction.data}
                                locale={{
                                    emptyText: (transaction.data) ?
                                        null
                                        : (
                                            <Button
                                                onClick={() => handleTransaction()}
                                                size="large"
                                                className="btn btn-secondary">
                                                {"Afficher l'historique des transactions"}
                                            </Button>
                                        )
                                }}
                                loading={transaction.isFetching}
                                columns={columns} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
export default BillDstvPage;
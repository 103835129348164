import React from 'react';
import { Button } from 'antd';


export function CustomBtn({ title }) {
    return (
        <Button
            htmlType="submit"
            className="btn btn-secondary" > {title} </Button>
    );
}

export default CustomBtn;

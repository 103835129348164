import React, { useState } from 'react';
import { Result, Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '../../redux/dialog/dialog-slice';

export function ErrorDialog() {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(true);
    const dialog = useSelector((state) => state.dialog);

    return (
        <Modal
            visible={isModalVisible}
            centered
            footer={null}
            closable={false}
            className="error-dialog">
            <Result
                status="error"
                title={dialog.data.code}
                subTitle={dialog.errorMessage}
                extra={[
                    <Button className="btn btn-primary" key="console" onClick={() => {
                        setIsModalVisible(false); dispatch(showDialog({ isError: false, errorMessage: "" }))
                    }}>Fermer</Button>
                ]} />
        </Modal>
    );
}

export default ErrorDialog;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { API_ROOT } from '../../utils/api-config';
import { handleApiError } from '../../utils/errorHandler';
import { saveAgentState, loadAgentState } from '../loadStorage';
import { showDialog } from '../dialog/dialog-slice';

export const loginOauthAsync = createAsyncThunk(
    'auth/loginOauthAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}register_oauth_app/`,
                headers: {
                    'Content-Type': 'application/json',
                    'App-Platform': '1'
                },
                data: payload
            });
            const dataToken = {
                "auth": true,
                "fromWeb": true,
                "grant_type": "password",
                "client_type": 'public',
                "country": payload.country,
                ...response.data,
            }
            dispatch(getTokenAsync(dataToken));
            return response.data
        } catch (error) {
            return rejectWithValue(handleApiError(error.response.data))
        }
    }
);

export const getTokenAsync = createAsyncThunk(
    'auth/getTokenAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}token/`,
                headers: {
                    'Content-Type': 'application/json',
                    'App-Platform': '1'
                },
                data: payload
            });
            saveAgentState(response.data)
            return response.data
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);

export const checkStatusAsync = createAsyncThunk(
    'auth/checkStatusAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/check_status/`,
                headers: {
                    'Content-Type': 'application/json',
                    'App-Platform': '1'
                },
                data: payload
            });
            dispatch(sendOtpAsync(payload));
            return response.data
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);

export const sendOtpAsync = createAsyncThunk(
    'auth/sendOtpAsync',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/send_otp_code/`,
                headers: {
                    'Content-Type': 'application/json',
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data
        } catch (error) {
            return rejectWithValue(handleApiError(error.response))
        }
    }
);


export const activateAsync = createAsyncThunk(
    'auth/activateAsync',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/activate/`,
                headers: {
                    'Content-Type': 'application/json',
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data
        } catch (error) {
            return rejectWithValue(handleApiError(error.response.data))
        }
    }
);

export const updateAsync = createAsyncThunk(
    'auth/updateAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/update_pin/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload
            })
            dispatch(showDialog({ isSuccess: true, successMessage: "Votre code Pin a été modifié avec succèss" }))
            return response.data
        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data.error) })))
        }
    }
);

export const getTrainingInfoAsync = createAsyncThunk(
    'auth/getTrainingInfoAsync',
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/training/info/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error training:", error.response.data)
        }
    }
);

export const saveTrainingInfoAsync = createAsyncThunk(
    'auth/saveTrainingInfoAsync',
    async (payload) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/training/save/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                },
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error training:", error.response.data)
        }
    }
);

export const setPinAsync = createAsyncThunk(
    'auth/setPinAsync',
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${API_ROOT}api/set_pin/`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${payload.token}`,
                    'App-Platform': '1'
                },
                data: payload
            });
            return response.data

        } catch (error) {
            if ((error.response.data.message === "TOKEN_EXPIRED"))
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            if (error.response.data.message === "Unauthorized")
                return rejectWithValue(dispatch(showDialog({ isLockscreen: true })))
            else
                return rejectWithValue(dispatch(showDialog({ isError: true, errorMessage: handleApiError(error.response.data) })))
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'idle',
        currentAgent: loadAgentState(),
        isFetching: false,
        isSuccess: false,
        isSendOtp: false,
        isTraining: false,
        errorMessage: "",
        successMessage: "",
        isPinSettedSuccess: false,
        training: "",
    },
    reducers: {
        clearState: (state) => {
            state.status = 'idle';
            state.isSuccess = false;
            state.isFetching = false;
            state.isSendOtp = false;
            state.isTraining = false;
            state.isPinSettedSuccess = false;
            state.errorMessage = "";
            state.successMessage = "";
            state.training = "";
            return state;
        },
    },
    extraReducers: {
        // Login
        [loginOauthAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true
        },

        [loginOauthAsync.fulfilled](state) { },

        [loginOauthAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.errorMessage = payload
        },

        // Token
        [getTokenAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [getTokenAsync.fulfilled](state, { payload }) {
            state.status = 'success'
            state.isFetching = false
            state.currentAgent = payload
        },
        [getTokenAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.errorMessage = payload
        },

        // Check Status
        [checkStatusAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [checkStatusAsync.fulfilled](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [checkStatusAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.errorMessage = payload
        },

        // Send OTP
        [sendOtpAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [sendOtpAsync.fulfilled](state) {
            state.isFetching = false
            state.isSendOtp = true
        },
        [sendOtpAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.errorMessage = payload
        },

        // Activate
        [activateAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [activateAsync.fulfilled](state) {
            state.status = 'success'
            state.isFetching = false
        },
        [activateAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.errorMessage = payload
        },


        // Update
        [updateAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [updateAsync.fulfilled](state) {
            state.status = 'success'
            state.isFetching = false
        },
        [updateAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.errorMessage = payload
        },

        // Training 
        [getTrainingInfoAsync.pending](state) {
            // state.isFetching = true;
        },
        [getTrainingInfoAsync.fulfilled](state, { payload }) {
            // state.isFetching = false;
            state.training = payload
            state.isTraining = payload?.code === "201" ? true : false
        },
        [getTrainingInfoAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        [saveTrainingInfoAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [saveTrainingInfoAsync.fulfilled](state) {
            state.status = 'success'
            state.isTraining = false
            state.isFetching = false
        },
        [saveTrainingInfoAsync.rejected](state) {
            state.status = 'failed'
            state.isFetching = false
        },

        // SetPin
        [setPinAsync.pending](state) {
            state.status = 'pending'
            state.isFetching = true;
        },
        [setPinAsync.fulfilled](state) {
            state.status = 'success'
            state.isFetching = false
            state.isPinSettedSuccess = true
        },
        [setPinAsync.rejected](state, { payload }) {
            state.status = 'failed'
            state.isFetching = false
            state.errorMessage = payload
        },
    }
});

export const { clearState } = authSlice.actions;
export default authSlice.reducer;